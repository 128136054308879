<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <h1 class="text-h2 font-weight-bold" style="color: #0b295b">
          {{ $t("userManagement.title") }}
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <UserSearchBar @submit="t => filterUsers(t)" />
      </v-col>
    </v-row>

    <v-row class="mt-8">
      <ManagedUserTable
        @approve="p => approveUser(p)"
        @submit="p => addUserRevision(p)"
        @reset="p => resetIDNow(p)"
        @load-more="handleLoadMore"
        @migrate="
              p =>
                migrateLicense({
                  userId: p.userSelectedId,
                  newUserId: p.newUserId,
                  licenseId: p.licenseId,
                })
            "
        :users="users.data"
      />
    </v-row>
  </v-container>
</template>

<script lang="ts" setup>
import UserSearchBar from "@/components/user-management/UserSearchBar.vue";
import ManagedUserTable from "@/components/user-management/ManagedUserTable.vue";
import { useUserManagementLogic } from "./user-management-logic";

const {
  users,
  handleLoadMore,
  approveUser,
  filterUsers,
  addUserRevision,
  resetIDNow,
  migrateLicense,
} = useUserManagementLogic();
</script>

<style scoped></style>
