<template>
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      class="cls-1"
      d="M9.5 16H3C2.17275 16 1.5 15.3272 1.5 14.5V4.5H2.5V14.5C2.5 14.7758 2.72425 15 3 15H9.5V16Z"
      fill="#080027"
    />
    <path
      class="cls-1"
      d="M12.871 0H5.12875C4.23075 0 3.5 0.7305 3.5 1.629V12.371C3.5 13.2692 4.23075 14 5.129 14H12.8713C13.7695 14 14.5003 13.2692 14.5003 12.371V1.629C14.5 0.7305 13.7693 0 12.871 0ZM12.5 7H5.5V6H12.5V7ZM12.5 5H5.5V4H12.5V5ZM12.5 3H5.5V2H12.5V3Z"
      fill="#080027"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.cls-1 {
  fill: #14005c;
}
</style>
