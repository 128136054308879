// Composables
import GuestLayout from "@/layouts/GuestLayout.vue";
import LoginPage from "@/views/general/login-page/LoginPage.vue";

export default [
  {
    path: "/",
    component: GuestLayout,
    children: [
      {
        path: "login",
        name: "login",
        component: LoginPage,
        meta: {
          title: "Willkommen bei CONROO! | CONROO Webapp",
          requiresAuth: false,
          roles: [],
        },
      },
    ],
  },
];
