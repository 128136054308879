<template>
  <svg viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1461_2030)">
      <path
        class="cls-1"
        d="M13.9999 2.5332C7.55992 2.5332 2.33325 7.75987 2.33325 14.1999C2.33325 20.6399 7.55992 25.8665 13.9999 25.8665C20.4399 25.8665 25.6666 20.6399 25.6666 14.1999C25.6666 7.75987 20.4399 2.5332 13.9999 2.5332ZM4.66659 14.1999C4.66659 9.0432 8.84325 4.86654 13.9999 4.86654C16.1583 4.86654 18.1416 5.60154 19.7166 6.8382L6.63825 19.9165C5.40159 18.3415 4.66659 16.3582 4.66659 14.1999ZM13.9999 23.5332C11.8416 23.5332 9.85825 22.7982 8.28325 21.5615L21.3616 8.4832C22.5983 10.0582 23.3333 12.0415 23.3333 14.1999C23.3333 19.3565 19.1566 23.5332 13.9999 23.5332Z"
        fill="#14005C"
      />
    </g>
    <defs>
      <clipPath id="clip0_1461_2030">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="translate(-0.000244141 0.199707)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.cls-1 {
  fill: #14005c;
}
</style>
