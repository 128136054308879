<template>
  <svg
    id="Ebene_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76.31 94.33"
  >
    <path
      class="train-1"
      d="M12.77,59.64c0,3.88,3.16,7.03,7.03,7.03s7.03-3.16,7.03-7.03-3.16-7.03-7.03-7.03-7.03,3.16-7.03,7.03Zm10.57,0c0,1.95-1.58,3.53-3.53,3.53s-3.53-1.58-3.53-3.53,1.58-3.53,3.53-3.53,3.53,1.58,3.53,3.53Z"
    />
    <path
      class="train-1"
      d="M50.12,59.64c0,3.88,3.16,7.03,7.03,7.03s7.03-3.16,7.03-7.03-3.16-7.03-7.03-7.03-7.03,3.16-7.03,7.03Zm10.57,0c0,1.95-1.58,3.53-3.53,3.53s-3.53-1.58-3.53-3.53,1.58-3.53,3.53-3.53,3.53,1.58,3.53,3.53Z"
    />
    <path
      class="train-1"
      d="M74.8,91.56l-13.02-17.98h10.07c1.7,0,3.09-1.39,3.09-3.09V20.97c0-1.24-1.02-6.44-4.07-8.43-1.01-.83-4.96-3.09-18.47-3.79V3.53h4.76c.97,0,1.75-.78,1.75-1.75S58.13,.03,57.16,.03h-4.76v-.03H24.55V.03h-4.76c-.97,0-1.75,.78-1.75,1.75s.78,1.75,1.75,1.75h4.76v5.26c-13.23,.75-17.57,3.04-18.5,3.65C1.87,14.61,1.82,20.71,1.82,20.97v7.28c0,.97,.78,1.75,1.75,1.75s1.75-.78,1.75-1.75v-7.28c0-.9,.16-4.22,2.39-5.45l.24-.15c3.52-1.63,10.16-3.33,30.74-3.33s26.78,.7,29.93,3.18l.29,.22c1.56,1.3,2.56,4.3,2.53,5.52v49.1H5.32v-29.65c0-.97-.78-1.75-1.75-1.75s-1.75,.78-1.75,1.75v30.06c0,1.7,1.39,3.09,3.09,3.09H15.82L3.87,91.13c-.54,.8-.34,1.89,.46,2.43,.3,.21,.64,.3,.98,.3,.56,0,1.11-.27,1.45-.77l13.07-19.2c.07-.11,.13-.22,.17-.33H57.46l14.5,20.04c.34,.47,.88,.72,1.42,.72,.36,0,.71-.11,1.03-.33,.78-.57,.96-1.66,.39-2.44ZM28.05,8.64V3.53h20.86v5.09c-1.62-.05-8.35-.07-10.21-.07-2.02,0-8.9,.03-10.65,.09Z"
    />
    <path
      class="train-1"
      d="M55.53,80.62c.97,0,1.75-.78,1.75-1.75s-.78-1.75-1.75-1.75H21.63c-.97,0-1.75,.78-1.75,1.75s.78,1.75,1.75,1.75H55.53Z"
    />
    <path
      class="train-1"
      d="M61.92,86.25H15.24c-.97,0-1.75,.78-1.75,1.75s.78,1.75,1.75,1.75H61.92c.97,0,1.75-.78,1.75-1.75s-.78-1.75-1.75-1.75Z"
    />
    <path
      class="train-1"
      d="M65.49,42.91V21.25c0-1.63-1.59-2.85-3.69-2.85H15.15c-2.1,0-3.69,1.23-3.69,2.85v21.66c0,1.58,1.53,2.77,3.55,2.77H61.94c2.02,0,3.55-1.19,3.55-2.77Zm-3.5-.73H14.96V21.91c.06,0,.12-.01,.19-.01H61.8c.07,0,.13,0,.19,.01v20.27Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>
<style lang="scss">
.train-1 {
  fill: white;
}
</style>
