<template>
  <v-menu>
    <template #activator="{ props: activatorProps }">
      <v-btn v-bind="activatorProps" class="w-full" variant="outlined">
        {{ valueToShow }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in props.entries"
        :key="index"
        @click="emitSelect(item)"
      >
        <v-list-item-title>{{ item }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
  entries: string[];
  title: string;
  selectedValue: string | null | undefined;
}>();

const valueToShow = computed(() =>
  props.selectedValue == null ? props.title : props.selectedValue,
);

const emit = defineEmits<{
  select: [value: string];
}>();

const emitSelect = (item: string): void => {
  emit("select", item);
};
</script>
