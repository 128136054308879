import "vuetify/styles";
import { createVuetify } from "vuetify";
import { mdi } from "vuetify/iconsets/mdi";
import "@mdi/font/css/materialdesignicons.css";
import { customSVGs } from "@/assets/iconSets/customSVG";

const vuetify = createVuetify({
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        variables: {}, // this property is required to avoid Vuetify crash
        colors: {
          primary: "#14005c",
          secondary: "#b0bec5",
          anchor: "#8c9eff",
          greyish: "#E5E9EE",
          darkGrey: "#A1A1A1",
          lightPink: "#FAA0A0",
          pinkish: "#ff005b",
          error: "#ff453a",
          lightGreen: "#c9e08c",
          green: "#94C119",
          lightestBlue: "#bfc9d7",
          lightBlue: "#3f5d85",
          darkBlue: "#1e2f55",
          darkBlue2: "#00295B",
          creamWhite: "#ffffe6",
        },
      },
    },
  },
  icons: {
    defaultSet: "mdi",
    sets: {
      mdi,
      custom: customSVGs,
    },
  },
});

export default vuetify;
