import { Composer } from "vue-i18n";
import i18n from "@/plugins/i18n";
import {
  ConfirmBookingEntries,
  TrainOperator,
  TrainOperatorBooking,
  TrainOperatorBookingsApi,
} from "@/services/client/generated";
import { dateService, DateService } from "@/services/business/date-service";
import DateTimeFormatOptions from "@/internal-models/date-time-options";
import ColumnData from "@/internal-models/column-data";
import { useAuthStore } from "@/store/useAuthStore";
import { useTerminalStore } from "@/store/useTerminalStore";
import { trainOperatorBookingsClient } from "@/services/client/configs/services";

export class TrainOperatorBookingsLogic {
  constructor(
    private authStore: ReturnType<typeof useAuthStore>,
    private terminalStore: ReturnType<typeof useTerminalStore>,
    private dateService: DateService,
    private trainOperatorBookingsService: TrainOperatorBookingsApi,
    private vueI18n: Composer,
  ) {}

  async getTrainOperators(): Promise<TrainOperator[]> {
    const userId = this.authStore.authUser?.userId ?? "";
    const response =
      await this.trainOperatorBookingsService.getAvailableTrainOperators(
        userId,
      );
    return response.data;
  }

  //TODO: check on BO model
  async getBookingsForTrainOperator(
    trainOperatorId: number,
    page: number,
    size: number,
    active: boolean,
  ): Promise<TrainOperatorBooking[]> {
    const userId = this.authStore.authUser?.userId ?? "";
    const terminalId = this.terminalStore.getTerminal()?.id ?? 0;
    const response =
      await this.trainOperatorBookingsService.getBookingsForTrainOperator(
        userId,
        trainOperatorId,
        terminalId,
        page,
        size,
        active,
      );
    return response.data;
  }

  async confirmBookingForTrainOperator(
    bookingIds: number[],
    currentTrainOperatorId: number,
  ): Promise<void> {
    const userId = this.authStore.authUser?.userId ?? "";
    const terminalId = this.terminalStore.getTerminal()?.id ?? 0;
    const confirmBookingEntries: ConfirmBookingEntries = { bookingIds };
    await this.trainOperatorBookingsService.confirmBookingEntries(
      userId,
      currentTrainOperatorId,
      terminalId,
      confirmBookingEntries,
    );
  }

  getParsedDate(date: string): string {
    const options: DateTimeFormatOptions = {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    if (date) {
      return this.dateService.parseReadableDateTimeFormat(
        date!,
        this.vueI18n.locale.value,
        options,
      );
    }
    return "";
  }

  getParsedSlotTime(slotBegin: string, slotEnd: string): string {
    const options: DateTimeFormatOptions = {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
    };
    const parsedFirstDate = this.dateService.parseReadableDateTimeFormat(
      slotBegin,
      this.vueI18n.locale.value,
      options,
    );
    const timeOfSlotBegin = this.dateService.getHour(slotBegin, {
      showMinutes: false,
    });
    const timeOfSlotEnd = this.dateService.getHour(slotEnd, {
      showMinutes: false,
      showSuffix: true,
    });
    return `${parsedFirstDate} ${timeOfSlotBegin}-${timeOfSlotEnd}`;
  }

  getDifferenceInHours(start: string, end: string): number {
    return this.dateService.getDifferenceInHours(start, end);
  }

  // Booking Tiles utils
  baseTextSize(vuetifyBreakPointName: string): string {
    //TODO: add scss and mediaQueries instead of this
    switch (vuetifyBreakPointName) {
      case "xs":
        return "1.5w";
      case "sm":
        return "1.3vw";
      case "md":
        return "1.2vw";
      case "lg":
        return "1.0vw";
      case "xl":
        return "0.8vw";
      case "xxl":
        return "0.6vw";
    }
    throw new Error("breakpoint not found");
  }

  parsedLatestDate(trainOperatorBooking: TrainOperatorBooking): string {
    const latestLoadingTime = trainOperatorBooking?.latestLoadingTime ?? "";
    return this.getParsedDate(latestLoadingTime);
  }

  truckerName(trainOperatorBooking: TrainOperatorBooking): string {
    const { firstName, lastName } = trainOperatorBooking;
    if (firstName && lastName) return `${firstName} ${lastName}`;

    return "";
  }

  fallBackMissing(value: string | number | undefined): string {
    if (!value) return i18n.global.t("missing") as string;
    const returnValue = value.toString();
    if (returnValue.length === 0) return i18n.global.t("missing") as string;
    return returnValue;
  }

  toTrainType(value: string): string {
    if (!value || value === "") return this.fallBackMissing(value);
    if (value === "pickup") return "import";
    return "export";
  }

  divideIntoThousands(value: number | undefined): string {
    if (value === undefined) return this.fallBackMissing(value);
    const formattingRegexHundreds = /\B(?=(\d{3})+(?!\d))/g;
    return value.toString().replace(formattingRegexHundreds, ".");
  }

  cardColor(trainOperatorBooking: TrainOperatorBooking): string {
    const red = "#fb817ebf";
    const blue = "#bfc9d7ff";
    if (
      trainOperatorBooking.latestLoadingTime &&
      trainOperatorBooking.slotBegin
    ) {
      const differenceInHours = this.getDifferenceInHours(
        trainOperatorBooking.slotBegin,
        trainOperatorBooking.latestLoadingTime,
      );
      if (differenceInHours >= 24 || differenceInHours <= 2) return red;
      else return blue;
    }
    return blue;
  }

  parsedSlotTime(trainOperatorBooking: TrainOperatorBooking): string {
    const { slotBegin, slotEnd } = trainOperatorBooking;
    if (slotBegin && slotEnd) {
      return this.getParsedSlotTime(slotBegin, slotEnd);
    }
    return "";
  }

  allColumnData(trainOperatorBooking: TrainOperatorBooking): ColumnData[] {
    return [
      {
        style: "{ 'font-size': this.baseTextSize }",
        class: "tile-text text-capitalize",
        id: "type",
        align: "center",
        data: this.toTrainType(trainOperatorBooking.type),
      },
      {
        style: "{ 'font-size': this.baseTextSize }",
        class: "tile-text",
        id: "referenceNumber",
        align: "center",
        data: this.fallBackMissing(trainOperatorBooking.referenceNumber),
      },

      {
        style: "{ 'font-size': this.baseTextSize }",
        class: "tile-text",
        id: "trainOperatorBookings.containerNumber",
        align: "center",
        data: this.fallBackMissing(trainOperatorBooking.containerNumber),
      },

      {
        style: "{ 'font-size': this.baseTextSize }",
        class: "tile-text",
        id: "containerLength",
        align: "center",
        data: this.fallBackMissing(trainOperatorBooking.containerLength),
      },

      {
        style: "{ 'font-size': this.baseTextSize }",
        class: "tile-text",
        id: "netWeight",
        align: "center",
        data: this.divideIntoThousands(trainOperatorBooking.netWeight),
      },
      {
        style: "{ 'font-size': this.baseTextSize }",
        class: "tile-text",
        id: "emptyWeight",
        align: "center",
        data: this.divideIntoThousands(trainOperatorBooking.emptyWeight),
      },
      {
        style: "{ 'font-size': this.baseTextSize }",
        class: "tile-text",
        id: "forwarder",
        align: "center",
        data: this.fallBackMissing(trainOperatorBooking.forwarder),
      },

      {
        style: "{ 'font-size': this.baseTextSize }",
        class: "tile-text",
        id: "driver",
        align: "center",
        data: this.fallBackMissing(this.truckerName(trainOperatorBooking)),
      },

      {
        style: "{ 'font-size': this.baseTextSize }",
        class: "tile-text",
        id: "licensePlate",
        align: "center",
        data: this.fallBackMissing(trainOperatorBooking.licensePlate),
      },
      {
        style: "{ 'font-size': this.baseTextSize }",
        class: "tile-text",
        id: "slotTime",
        align: "center",
        data: this.fallBackMissing(this.parsedSlotTime(trainOperatorBooking)),
      },
      {
        style: "{ 'font-size': this.baseTextSize }",
        class: "tile-text",
        id: "trainOperatorBookings.lastLoadingTime",
        align: "center",
        data: this.fallBackMissing(this.parsedLatestDate(trainOperatorBooking)),
      },

      {
        style: "{ 'font-size': this.baseTextSize }",
        class: "tile-text",
        id: "transhipmentDate",
        align: "center",
        data: this.fallBackMissing(
          this.getParsedDate(
            trainOperatorBooking.transhipmentTaskConfirmation!,
          ),
        ),
      },

      {
        style: "{ 'font-size': this.baseTextSize }",
        class: "tile-text",
        id: "trainOperatorBookings.trainNumber",
        align: "center",
        data: this.fallBackMissing(trainOperatorBooking.trainNumber),
      },
    ];
  }
}

let viewModel: TrainOperatorBookingsLogic | null = null;

export const getViewModel = (): TrainOperatorBookingsLogic => {
  if (!viewModel) {
    viewModel = new TrainOperatorBookingsLogic(
      useAuthStore(),
      useTerminalStore(),
      dateService,
      trainOperatorBookingsClient,
      i18n.global as Composer,
    );
  }
  return viewModel;
};
