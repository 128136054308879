<template>
  <v-dialog :model-value="modelValue" max-width="600">
    <v-card>
      <v-card-title class="text-center">
        <v-row justify="center" align="center">
          <v-col>
            <span class="text-xl md:text-2xl">
              {{ $t("userManagement.migrate_license") }}
            </span>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row justify="center" align="center">
          <v-col cols="12">
            {{ $t("userManagement.migrate_warning_text") }}
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="newUserId"
              variant="outlined"
              :label="$t('userManagement.new_user_id')"
              :placeholder="$t('userManagement.new_user_id_placeholder')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          @click="submitNewId"
          color="success"
          variant="flat"
          :disabled="!newUserId"
        >
          {{ $t("accept") }}
        </v-btn>
        <v-btn @click="closeDialog">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref } from "vue";

defineProps<{
  modelValue: boolean;
}>();

const dialog = ref(false);
const newUserId = ref("");

const emit = defineEmits<{
  (e: "confirm", newUserId: string): void;
  (e: "close"): void;
}>();

const submitNewId = () => {
  emit("confirm", newUserId.value);
  closeDialog();
};

const closeDialog = () => {
  dialog.value = false;
  newUserId.value = "";
  emit("close");
};
</script>
