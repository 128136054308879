<template>
  <div>
    <div v-if="loading">
      <v-progress-circular indeterminate color="red" />
    </div>

    <div v-else class="mt-6">
      <v-select
        class="mx-3"
        density="compact"
        :items="terminals ?? []"
        :no-data-text="$t('no_data_available')"
        :label="$t('terminals')"
        item-title="name"
        v-model="selectedTerminal"
        @update:modelValue="changeTerminal"
        variant="outlined"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useAuthStore } from "@/store/useAuthStore";
import { useTerminalStore } from "@/store/useTerminalStore";
import { Terminal } from "@/services/client/generated";

const authStore = useAuthStore();
const terminalStore = useTerminalStore();

const terminals = ref<Terminal[] | undefined>(undefined);
const selectedTerminal = ref<string | null>(null);
const loading = ref(true);

const fetchTerminals = async (): Promise<void> => {
  const userId = authStore.authUser?.userId;
  if (userId) {
    const terminalData = await terminalStore.getTerminals(userId);
    terminals.value = terminalData;

    const terminal = terminalStore.getTerminal();
    if (terminal) {
      selectedTerminal.value = terminal.name;
    } else if (terminals.value && terminals.value.length > 0) {
      selectedTerminal.value = terminals.value[0].name;
      terminalStore.setTerminalData(terminals.value[0]);
    }
  }

  loading.value = false;
};

const changeTerminal = async (value: string) => {
  selectedTerminal.value = value;
  if (selectedTerminal.value && terminals.value) {
    const terminalEntity = terminals.value.find(
      e => e.name === selectedTerminal.value,
    );
    if (terminalEntity) {
      terminalStore.setTerminalData(terminalEntity);
    }
  }
};

onMounted(async () => {
  await fetchTerminals();
});
</script>

<style scoped>
.loading-title {
  font-weight: bold;
  font-size: 2vh;
}
</style>
