import { createI18n } from "vue-i18n";
import { messages, defaultLocale } from "@/i18n";

const i18n = createI18n({
  legacy: false,
  allowComposition: true,
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  globalInjection: true,
});

export default i18n;
