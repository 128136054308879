export const deleteAllKeys = (o: Record<string, unknown>) => {
  Object.keys(o).forEach(key => delete o[key]);
};

export const getLastArrayElement = <T>(list: T[]): T | undefined => {
  if (list.length === 0) {
    return undefined;
  }

  return list[list.length - 1];
};
