<template>
  <v-table>
    <thead>
      <tr>
        <th>{{ $t("userManagement.change_id") }}</th>
        <th>{{ $t("userManagement.approved_by") }}</th>
        <th>{{ $t("userManagement.completed_at") }}</th>
        <th>{{ $t("userManagement.terror_status") }}</th>
        <th>{{ $t("userManagement.document_status") }}</th>
        <th>{{ $t("first_name") }}</th>
        <th>{{ $t("last_name") }}</th>
        <th>{{ $t("userManagement.creator") }}</th>
        <th>{{ $t("userManagement.change_state") }}</th>
        <th>{{ $t("reset") }}</th>
        <th>{{ $t("actions") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="change in getOnlyRelevantChanges(props.users!)"
        :key="change.id"
      >
        <td>{{ change.id }}</td>
        <td>{{ getSupervisor(change.supervisor) }}</td>
        <td>{{ format(change.completedAt) }}</td>
        <td>{{ change.terrorStatus }}</td>
        <td>{{ change.documentStatus }}</td>
        <td>{{ change.firstName }}</td>
        <td>{{ change.lastName }}</td>
        <td>{{ getCreatorName(change) }}</td>
        <td>{{ change.state }}</td>
        <td>{{ change.reset }}</td>
        <td>
          <v-row
            v-if="
              isAdmin(userStore.getUser()?.userData?.roles ?? []) &&
              change.state === 'PENDING' &&
              change.creator?.id != user?.userData?.id
            "
          >
            <v-col cols="6">
              <v-btn
                color="success"
                size="small"
                @click="emit('approve', { approve: true, id: change.id! })"
              >
                {{ $t("approve") }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                color="error"
                size="small"
                @click="emit('approve', { approve: false, id: change.id! })"
              >
                {{ $t("deny") }}
              </v-btn>
            </v-col>
          </v-row>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script lang="ts" setup>
import { IspsTruckerChangeTO } from "@/services/client/generated";
import { isAdmin } from "@/utils/role-utils";
import { useAuthStore } from "@/store/useAuthStore";
import { useI18n } from "vue-i18n";
import { formatDate } from "@/utils/date-utils";

const userStore = useAuthStore();
const user = userStore.getUser();
const { t } = useI18n();

const emit = defineEmits<{
  approve: [payload: { approve: boolean; id: number }];
}>();

const format = (date?: string) => {
  if (!date) {
    return t("missing");
  }

  return formatDate(date);
};

const getOnlyRelevantChanges = (changes: IspsTruckerChangeTO[]) => {
  //TODO: backend fix, they are showing in oldest order on top
  return changes.filter(
    e =>
      e.state === "PENDING" || e.state === "SYSTEM" || e.state === "APPROVED",
  );
};

const getSupervisor = (supervisor?: {
  firstName?: string;
  lastName?: string;
}) => {
  if (!supervisor || !supervisor.firstName || !supervisor.lastName) {
    return t("missing");
  }
  return `${supervisor.firstName} ${supervisor.lastName}`;
};

const getCreatorName = (change: IspsTruckerChangeTO) => {
  if (change.state === "SYSTEM") {
    return "SYSTEM";
  }

  return `${change.creator?.firstName} ${change.creator?.lastName}`;
};

const props = defineProps<{ users?: IspsTruckerChangeTO[] }>();
</script>
