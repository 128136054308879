<template>
  <v-dialog
    :model-value="modelValue"
    max-width="400"
    data-testid="test-confirmModal"
  >
    <v-card>
      <v-card-text>
        <div class="text-xl md:text-2xl text-center mb-5">
          {{ customText }}
        </div>
      </v-card-text>
      <v-card-actions class="justify-between">
        <v-btn color="info" @click="sendSubmit">
          {{ customSubmit }}
        </v-btn>
        <v-btn color="error" @click="closeDialog">
          {{ customCancel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
const props = defineProps({
  userId: {
    type: String,
    required: true,
  },
  customText: {
    type: String,
    required: true,
  },
  customCancel: {
    type: String,
    required: false,
    default: "Cancel",
  },
  customSubmit: {
    type: String,
    required: false,
    default: "Submit",
  },
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits<{
  submit: [payload: { id: string }];
  close: [close: boolean];
}>();

const sendSubmit = () => {
  closeDialog();
  emit("submit", { id: props.userId });
};

const closeDialog = () => {
  emit("close", true);
};
</script>
