<template>
  <svg viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1461_1407)">
      <path
        d="M13.9999 2.73315C7.55992 2.73315 2.33325 7.95982 2.33325 14.3998C2.33325 20.8398 7.55992 26.0665 13.9999 26.0665C20.4399 26.0665 25.6666 20.8398 25.6666 14.3998C25.6666 7.95982 20.4399 2.73315 13.9999 2.73315ZM4.66659 14.3998C4.66659 9.24315 8.84325 5.06649 13.9999 5.06649C16.1583 5.06649 18.1416 5.80149 19.7166 7.03815L6.63825 20.1165C5.40159 18.5415 4.66659 16.5582 4.66659 14.3998ZM13.9999 23.7332C11.8416 23.7332 9.85825 22.9982 8.28325 21.7615L21.3616 8.68315C22.5983 10.2582 23.3333 12.2415 23.3333 14.3998C23.3333 19.5565 19.1566 23.7332 13.9999 23.7332Z"
        fill="#FDFDFD"
      />
    </g>
    <defs>
      <clipPath id="clip0_1461_1407">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="translate(-0.000244141 0.399658)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped></style>
