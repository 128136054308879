import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import i18n from "./plugins/i18n";
import Vue3Lottie from "vue3-lottie";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import "../firebase";
import firebase from "firebase/compat/app";
import { auth } from "../firebase";
import { useAuthStore } from "./store/useAuthStore";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(vuetify);
app.use(i18n);
app.use(Vue3Lottie);

app.mount("#app");

async function handleAuthStateChange(user: firebase.User | null) {
  const authStore = useAuthStore();

  if (user) {
    const localUser = authStore.getUser();
    authStore.setUser({
      userId: user.uid,
      emailVerified: user.emailVerified,
      accessToken: await user.getIdToken(),
      userData: localUser?.userData,
    });
  } else {
    authStore.setUser(null);
  }
}

auth.onAuthStateChanged(async user => {
  await handleAuthStateChange(user);
});
