import {
  AcceptanceRestrictionsApi,
  IspsLicensesApi,
  ForwarderBookingsApi,
  ForwarderContainersApi,
  ForwarderOperatorForwardersApi,
  ForwarderOperatorTerminalApiApi,
  ForwarderTruckersApiApi,
  IspsAdminUsersApi,
  IspsTerminalOperatorBlocklistApi,
  StatisticsApi,
  TerminalOperatorTruckerApi,
  TerminalUsersApi,
  TrainOperatorBookingsApi,
  WebappTruckersApi,
  WebappStatisticsForForwarderApi,
} from "@/services/client/generated/api";
import axiosApiInstance from "@/services/client/configs/axios-interceptor";

const isJsonMime = () => true;

export const forwarderOperatorClient = new ForwarderOperatorForwardersApi(
  { isJsonMime },
  axiosApiInstance.defaults.baseURL,
  axiosApiInstance,
);

export const terimnalUserClient = new TerminalUsersApi(
  { isJsonMime },
  axiosApiInstance.defaults.baseURL,
  axiosApiInstance,
);

export const forwarderBookingsClient = new ForwarderBookingsApi(
  { isJsonMime },
  axiosApiInstance.defaults.baseURL,
  axiosApiInstance,
);

export const webappStatiscticsForwarderClient =
  new WebappStatisticsForForwarderApi(
    { isJsonMime },
    axiosApiInstance.defaults.baseURL,
    axiosApiInstance,
  );

export const forwarderContainersClient = new ForwarderContainersApi(
  { isJsonMime },
  axiosApiInstance.defaults.baseURL,
  axiosApiInstance,
);

export const forwarderTruckersClient = new ForwarderTruckersApiApi(
  { isJsonMime },
  axiosApiInstance.defaults.baseURL,
  axiosApiInstance,
);

export const forwarderOperatorTerminalClient =
  new ForwarderOperatorTerminalApiApi(
    { isJsonMime },
    axiosApiInstance.defaults.baseURL,
    axiosApiInstance,
  );

export const acceptanceRestrictionsClient = new AcceptanceRestrictionsApi(
  { isJsonMime },
  axiosApiInstance.defaults.baseURL,
  axiosApiInstance,
);

export const statisticsClient = new StatisticsApi(
  { isJsonMime },
  axiosApiInstance.defaults.baseURL,
  axiosApiInstance,
);

export const trainOperatorBookingsClient = new TrainOperatorBookingsApi(
  { isJsonMime },
  axiosApiInstance.defaults.baseURL,
  axiosApiInstance,
);

export const ispsAdminUserClient = new IspsAdminUsersApi(
  { isJsonMime },
  axiosApiInstance.defaults.baseURL,
  axiosApiInstance,
);

export const terminalOperatorTruckerClient = new TerminalOperatorTruckerApi(
  { isJsonMime },
  axiosApiInstance.defaults.baseURL,
  axiosApiInstance,
);

export const ispsTerminalOperatorBlockList =
  new IspsTerminalOperatorBlocklistApi(
    { isJsonMime },
    axiosApiInstance.defaults.baseURL,
    axiosApiInstance,
  );

export const webappTruckersClient = new WebappTruckersApi(
  { isJsonMime },
  axiosApiInstance.defaults.baseURL,
  axiosApiInstance,
);

export const ispsLicensesClient = new IspsLicensesApi(
  { isJsonMime },
  axiosApiInstance.defaults.baseURL,
  axiosApiInstance,
);
