<template>
  <svg viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1342_3927)">
      <path
        d="M16.625 28.5996H5.25C3.80231 28.5996 2.625 27.4223 2.625 25.9746V8.47461H4.375V25.9746C4.375 26.4572 4.76744 26.8496 5.25 26.8496H16.625V28.5996Z"
        fill="#FDFDFD"
      />
      <path
        d="M22.524 25.0996H8.97502C7.40308 25.0996 6.12427 23.8208 6.12427 22.2489V3.45036C6.12471 1.87798 7.40352 0.599609 8.97546 0.599609H22.5244C24.0959 0.599609 25.3747 1.87798 25.3747 3.45036V22.2489C25.3747 23.8208 24.0959 25.0996 22.524 25.0996ZM8.97546 2.34961C8.36864 2.34961 7.87471 2.84355 7.87471 3.4508V22.2493C7.87471 22.8561 8.36821 23.35 8.97546 23.35H22.5244C23.1312 23.35 23.6251 22.8561 23.6251 22.2493V3.45036C23.6251 2.84311 23.1316 2.34917 22.5244 2.34917L8.97546 2.34961Z"
        fill="#FDFDFD"
      />
      <path
        d="M21.8748 4.09912H9.62476V5.84912H21.8748V4.09912Z"
        fill="#FDFDFD"
      />
      <path
        d="M21.8748 7.59961H9.62476V9.34961H21.8748V7.59961Z"
        fill="#FDFDFD"
      />
      <path
        d="M21.8748 11.0996H9.62476V12.8496H21.8748V11.0996Z"
        fill="#FDFDFD"
      />
    </g>
    <defs>
      <clipPath id="clip0_1342_3927">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="translate(-0.000244141 0.599609)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>
