<template>
  <v-dialog :model-value="modelValue" max-width="85vw">
    <v-card>
      <v-card-title class="text-center">
        <v-row justify="center" align="center">
          <v-col>
            <span class="text-xl md:text-2xl">
              {{ $t("userManagement.all_changes_to_user") }}:
            </span>
          </v-col>
          <v-col cols="auto">
            <v-btn @click="closeDialog" color="info" size="small">
              {{ $t("close_dialog") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <HistoryTable @approve="sendApproval" :users="props.users" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { IspsTruckerChangeTO } from "@/services/client/generated";
import HistoryTable from "@/components/user-management/HistoryTable.vue";
import { ref } from "vue";

const props = defineProps<{
  users?: IspsTruckerChangeTO[];
  modelValue: boolean;
}>();

const dialog = ref(false);

const emit = defineEmits<{
  approve: [payload: { approve: boolean; id: number }];
  close: [close: boolean];
}>();

const sendApproval = (payload: { approve: boolean; id: number }) => {
  closeDialog();
  emit("approve", payload);
};

const closeDialog = () => {
  dialog.value = false;
  emit("close", true);
};
</script>
