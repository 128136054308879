import { RouteRecordRaw } from "vue-router";

export function routesWithPrefix(
  prefix: string,
  routes: RouteRecordRaw[],
): RouteRecordRaw[] {
  return routes.map(route => {
    route.path = `${prefix}${route.path}`;

    return route;
  });
}
