import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { createSnackBar } from "@/utils/snackbar-utils";
import SnackbarType from "./interfaces/snackbar-type";
import Snackbar from "./interfaces/snackbar";
import SnackbarOptions from "./interfaces/snackbar-options";

export const useSnackbarStore = defineStore("snackbarStore", () => {
  const snackbarState = ref<Snackbar>(createSnackBar("", SnackbarType.INITIAL));

  const snackbarData = computed(() => snackbarState.value);

  const setSnackbarData = (snackbar: Snackbar) => {
    snackbarState.value = snackbar;
  };

  const showSnackbar = async (payload: SnackbarOptions) => {
    const { text, snackbarType } = payload;
    const snackbar = createSnackBar(text, snackbarType);

    setSnackbarData(snackbar);
    setTimeout(() => {
      const initialSnackbar = createSnackBar("", SnackbarType.INITIAL);
      setSnackbarData(initialSnackbar);
    }, 5000);
  };

  const clearSnackbar = () => {
    const initialSnackbar = createSnackBar("", SnackbarType.INITIAL);
    setSnackbarData(initialSnackbar);
  };

  return {
    snackbarState,
    snackbarData,
    setSnackbarData,
    showSnackbar,
    clearSnackbar,
  };
});
