<template>
  <v-form v-model="valid" class="v-responsive__content">
    <v-row class="pl-5">
      <v-col sm="12" md="7">
        <v-row>
          <v-col
            sm="7"
            md="3"
            class="text-white status-text text-left fit-content pr-1"
          >
            {{ $t("fileUpload") }}:
          </v-col>

          <v-col sm="5" md="6" class="pl-0">
            <TheFileInput
              :outlined="true"
              :label="$t('selectFile')"
              accept="text/csv"
              :rules="[
                (v: File[]) => !!v || $t('fieldRequired'),
                // 1mb file limit
                (v: File[]) =>
                  (v[0] && v[0].size < 1000000) || $t('fileTooBig'),
              ]"
              @change-file="setFile"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col align-self="center" cols="3">
        <v-spacer />
      </v-col>
      <v-col cols="12" md="2">
        <TheButton
          id="submit-button"
          :is-disabled="isResubmitBatchButtonDisabled"
          :loading="loading"
          :title="$t('request')"
          @click="submitCsv"
          color="#ff005b"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup lang="ts">
import { ref } from "vue";
import TheButton from "@/components/TheButton.vue";
import TheFileInput from "@/components/TheFileInput.vue";

const props = defineProps({
  terminalId: {
    type: Number,
    required: true,
  },
  isResubmitBatchButtonDisabled: {
    type: Boolean,
    required: false,
  },
});

const emit = defineEmits(["submit-csv"]);

const valid = ref(false);
const currentFile = ref<File>();
const loading = ref(false);

const setFile = (payload: { label: string; value: File }) => {
  currentFile.value = payload.value;
};

const submitCsv = (): void => {
  if (valid.value) {
    loading.value = true;
    emit("submit-csv", {
      file: currentFile.value,
      terminalId: props.terminalId,
    });
    loading.value = false;
  }
};
</script>

<style scoped lang="scss">
@import "../../../scss/fade-transitions";

:deep(.text-input) {
  input {
    padding-left: 15px;
  }
}
.status-text {
  font-size: 1rem;
  word-break: normal;
}
.fit-content {
  max-width: fit-content;
}

#submit-button:disabled {
  background-color: #ababab !important;
}
</style>
