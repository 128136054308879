<template>
  <svg
    id="Ebene_1_1"
    data-name="Ebene 1_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
  >
    <polygon
      class="gate-2"
      points="64 8 64 8 64 8 0 8 0 12 8.81 12 .348 56 4.421 56 12.883 12 30 12 30 18 14 18 14 42 18 42 18 22 46 22 46 42 50 42 50 18 34 18 34 12 51.117 12 59.579 56 63.652 56 55.191 12 64 12 64 8"
    />
    <path class="gate-2" d="m20,50h24v-26h-24v26Zm4-22h16v18h-16v-18Z" />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.gate-2 {
  fill: white;
}
</style>
