<template>
  <svg viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1461_2166)">
      <path
        class="cls-1"
        d="M13.9883 2.5332C7.54825 2.5332 2.33325 7.75987 2.33325 14.1999C2.33325 20.6399 7.54825 25.8665 13.9883 25.8665C20.4399 25.8665 25.6666 20.6399 25.6666 14.1999C25.6666 7.75987 20.4399 2.5332 13.9883 2.5332ZM13.9999 23.5332C8.84325 23.5332 4.66659 19.3565 4.66659 14.1999C4.66659 9.0432 8.84325 4.86654 13.9999 4.86654C19.1566 4.86654 23.3333 9.0432 23.3333 14.1999C23.3333 19.3565 19.1566 23.5332 13.9999 23.5332ZM14.5833 8.36654H12.8333V15.3665L18.9583 19.0415L19.8333 17.6065L14.5833 14.4915V8.36654Z"
        fill="#14005C"
      />
    </g>
    <defs>
      <clipPath id="clip0_1461_2166">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="translate(-0.000244141 0.199707)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.cls-1 {
  fill: #14005c;
}
</style>
