import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { TrainOperator } from "@/services/client/generated";

export const useTrainStore = defineStore(
  "trainStore",
  () => {
    const trainOperators = ref<TrainOperator[]>([]);
    const selectedTrainOperator = ref<TrainOperator | null>(null);

    const trainOperatorsData = computed(() => trainOperators.value);
    const selectedTrainOperatorData = computed(
      () => selectedTrainOperator.value,
    );

    const setTrainOperators = (operators: TrainOperator[]) => {
      trainOperators.value = operators;
    };

    const setSelectedTrainOperator = (operator: TrainOperator) => {
      selectedTrainOperator.value = operator;
    };

    return {
      trainOperators,
      selectedTrainOperator,
      trainOperatorsData,
      selectedTrainOperatorData,
      setTrainOperators,
      setSelectedTrainOperator,
    };
  },
  {
    persist: true,
  },
);
