<template>
  <div>
    <div v-if="loading" class="test-loading">
      <LoadingIndicator />
    </div>
    <div v-else class="test-no-longer-loading">
      <LineChart
        class="small background-chart rounded"
        :chart-data="dataCollection"
        :chart-title="title"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import LineChart from "@/components/charts/LineChart.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { LineChartData, TimeSeriesChartData } from "@/internal-models/charts";

const props = defineProps<{
  title: string;
  data: TimeSeriesChartData;
  loading: boolean;
}>();

const dataCollection = ref<LineChartData>({
  labels: [],
  datasets: [],
});

const fillData = (): void => {
  dataCollection.value = {
    labels: props.data.xAxis,
    datasets: [
      {
        borderColor: "red",
        data: props.data.yAxis,
        fill: false,
      },
    ],
  };
};

onMounted(() => {
  fillData();
});

watch(() => props.data, fillData);
</script>

<style lang="scss" scoped>
.background-chart {
  background-color: #e5e9ee;
  position: relative;
  height: 240px;
}

.opacity {
  opacity: 1;
}
</style>
