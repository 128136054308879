<template>
  <svg viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1341_3810)">
      <path
        d="M14 13.5247C17.6186 13.5247 20.5625 10.5807 20.5625 6.96216C20.5625 3.3436 17.6186 0.399658 14 0.399658C10.3814 0.399658 7.4375 3.3436 7.4375 6.96216C7.4375 10.5807 10.3814 13.5247 14 13.5247ZM14 2.14966C16.6534 2.14966 18.8125 4.30872 18.8125 6.96216C18.8125 9.6156 16.6534 11.7747 14 11.7747C11.3466 11.7747 9.1875 9.6156 9.1875 6.96216C9.1875 4.30872 11.3466 2.14966 14 2.14966Z"
        fill="#FDFDFD"
      />
      <path
        d="M18.3745 15.2747H9.62451C5.28232 15.2747 1.74951 18.8075 1.74951 23.1497V28.3997H26.2495V23.1497C26.2495 18.8075 22.7167 15.2747 18.3745 15.2747ZM24.4995 26.6497H3.49951V23.1497C3.49951 19.7722 6.24701 17.0247 9.62451 17.0247H18.3745C21.752 17.0247 24.4995 19.7722 24.4995 23.1497V26.6497Z"
        fill="#FDFDFD"
      />
    </g>
    <defs>
      <clipPath id="clip0_1341_3810">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="translate(-0.000244141 0.399658)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped></style>
