import { defineStore } from "pinia";
import { ref, computed } from "vue";
import {
  ForwarderOperatorConfig,
  GetStatusFormPreferences,
} from "./interfaces/user-role-config";

export const useForwarderOperatorStore = defineStore(
  "forwarderOperatorStore",
  () => {
    const forwarderOperatorConfigState = ref<ForwarderOperatorConfig>(
      {} as ForwarderOperatorConfig,
    );

    const forwarderOperatorConfig = computed(
      () => forwarderOperatorConfigState.value,
    );

    const forwarderOperatorGetStatusPreferences = computed(
      (): GetStatusFormPreferences | undefined => {
        return forwarderOperatorConfigState.value.getStatusFormPreferences;
      },
    );

    const setGetStatusPreferences = (preferences: GetStatusFormPreferences) => {
      forwarderOperatorConfigState.value.getStatusFormPreferences = preferences;
    };

    const getGetStatusPreferences = ():
      | GetStatusFormPreferences
      | undefined => {
      return forwarderOperatorConfigState.value.getStatusFormPreferences;
    };

    const rememberGetStatusPreferences = (
      references: GetStatusFormPreferences,
    ) => {
      setGetStatusPreferences(references);
    };

    return {
      forwarderOperatorConfigState,
      forwarderOperatorConfig,
      forwarderOperatorGetStatusPreferences,
      setGetStatusPreferences,
      getGetStatusPreferences,
      rememberGetStatusPreferences,
    };
  },
  {
    persist: true,
  },
);
