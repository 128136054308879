<template>
  <div>
    <TheMainHeader class="text-left pl-10 pt-10" :text="$t('dashboard')" />

    <v-container fluid class="pa-10">
      <v-row justify="center" align="center">
        <v-col cols="12" lg="7">
          <v-row>
            <v-col xs="12" sm="6">
              <ThePageCard
                height="12vh"
                :usable="true"
                :item="pageCards.statusRequest"
              />
            </v-col>
            <v-col xs="12" sm="6">
              <!--                  //TODO: add loop-->
              <ThePageCard
                height="12vh"
                :usable="true"
                :item="pageCards.truckerManagement"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col xs="12" sm="6">
              <ThePageCard
                height="12vh"
                :usable="false"
                :item="pageCards.analyticalDashboard"
              />
            </v-col>
            <v-col xs="12" sm="6">
              <ThePageCard
                height="12vh"
                :usable="true"
                :item="pageCards.bookingsOverview"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col xs="6" sm="12" lg="5">
          <v-row>
            <v-col xs="6" sm="4">
              <TheStatisticValueBox
                class="click"
                @click="goToBookingsOverview"
                :statistic-value="
                  state.statisticValues[state.statisticNames[0]]
                "
              />
            </v-col>
            <v-col xs="6" sm="4">
              <TheStatisticValueBox
                class="click"
                @click="goToTruckerManagement"
                :statistic-value="
                  state.statisticValues[state.statisticNames[1]]
                "
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onBeforeUnmount, reactive, watch } from "vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import ThePageCard from "@/components/ThePageCard.vue";
import TheStatisticValueBox from "@/components/TheStatisticValueBox.vue";
import StatisticValue from "@/internal-models/statistic-value";
import {
  ConstantForwarderStatisticFloatValueResponseTO,
  ConstantForwarderStatisticValueRequestTO,
} from "@/services/client/generated";
import { findBorderColor } from "@/utils/statistic-utils";
import { useRouter } from "vue-router";
import { getViewModel } from "./forwarder-dashboard-logic";
import getForwarderOperatorPageCards from "@/constants/forwarder-operator-page-cards";
import { useForwarderStore } from "@/store/useForwarderStore";

const router = useRouter();
const viewModel = getViewModel();
const state = reactive({
  forwarderOperatorPageCards: {},
  statisticValues: {} as Record<string, StatisticValue>,
  statisticNames: ["open_orders", "registered_truckers"],
  intervalId: -1,
  updateInterval: 1000 * 60,
});

const pageCards = getForwarderOperatorPageCards();
const forwarderStore = useForwarderStore();

const goToBookingsOverview = () => {
  router.push("forwarderOperator/bookingsOverview");
};

const goToTruckerManagement = () => {
  router.push("forwarderOperator/truckerManagement");
};

const updateStatisticValues = async () => {
  const results =
    await Promise.all<ConstantForwarderStatisticFloatValueResponseTO>(
      state.statisticNames.map(e =>
        viewModel.fetchDataForStatisticBox(pickFloatStatisticRequest(e)),
      ),
    );

  const result: Record<string, StatisticValue> = {};
  results.forEach(statistic => {
    const parsedResponse = parseFloatResponse(statistic);
    if (parsedResponse) {
      result[statistic.name] = parsedResponse;
    }
  });

  state.statisticValues = result;
};

const pickFloatStatisticRequest = (
  type: string,
): ConstantForwarderStatisticValueRequestTO => {
  return viewModel.pickFloatStatistic(type);
};

const parseFloatResponse = (
  response: ConstantForwarderStatisticFloatValueResponseTO,
): StatisticValue | null => {
  if (!response) return null;
  return {
    color: findBorderColor(response),
    title: response.name,
    unit: response.unit,
    value: response.floatValue,
  };
};

onMounted(async () => {
  state.intervalId = window.setInterval(async () => {
    await updateStatisticValues();
  }, state.updateInterval);
  await updateStatisticValues();
});

onBeforeUnmount(() => {
  if (state.intervalId !== -1) {
    clearInterval(state.intervalId);
  }
});

watch(
  () => forwarderStore.forwarder,
  async (newForwarder, oldForwarder) => {
    if (newForwarder?.id !== oldForwarder?.id) {
      await updateStatisticValues();
    }
  },
);
</script>

<style lang="scss" scoped>
.click {
  cursor: pointer;
}
</style>
