import * as dateFns from "date-fns";
import { format, fromZonedTime, toZonedTime } from "date-fns-tz";
import StoreToken from "@/internal-models/store-token";
import Duration from "@/internal-models/enums/duration";
import { TimeSeriesRequestDurationEnum } from "@/services/client/generated";
import DateTimeFormatOptions from "@/internal-models/date-time-options";
import HourParsingOptions from "@/internal-models/hour-parsing-options";

//TODO: check on another interface

export class DateService {
  DAY_IN_MILLISECONDS = 1000 * 3600 * 24;
  //not 730 to account for leap years
  TWO_YEARS_IN_DAYS = 731;
  inRange(date: string, days: number) {
    const parsedDate = new Date(date);
    if (dateFns.isToday(parsedDate)) return true;
    const now = new Date();
    const lastDate = dateFns.addDays(now, days);

    return (
      dateFns.isBefore(parsedDate, lastDate) && dateFns.isAfter(parsedDate, now)
    );
  }

  //TODO: breaks dependency inversion to use a model of the library
  add(date: Date, duration: dateFns.Duration) {
    return dateFns.add(date, duration);
  }

  sub(date: string, duration: dateFns.Duration) {
    const parsedDate = new Date(date);
    return dateFns.sub(parsedDate, duration);
  }

  isBeforeDate(startDate: Date, endDate: Date) {
    return dateFns.isBefore(startDate, endDate);
  }

  isInPast(date: Date) {
    const actualDate = dateFns.addDays(date, 1);
    return dateFns.isPast(actualDate);
  }

  isEqual(startDate: Date, endDate: Date) {
    return dateFns.isEqual(startDate, endDate);
  }

  isInFuture(date: Date) {
    return dateFns.isFuture(date);
  }

  isAfterDate(date: string, secondDate: string) {
    const [startDate, endDate] = [new Date(date), new Date(secondDate)];
    return dateFns.isAfter(startDate, endDate);
  }

  orderDates(dates: string[]): Date[] {
    const parsedDates = dates.map(e => new Date(e));
    return parsedDates.sort((a, b) => dateFns.compareAsc(a, b));
  }

  isTokenExpired(storeToken: StoreToken): boolean {
    //TODO: could be used for pinia expiration
    const now = new Date();
    const issuedTime = new Date(storeToken.issuedTime);
    const lastValidTime = dateFns.addMinutes(
      issuedTime,
      storeToken.expiredTime,
    );
    return dateFns.isAfter(now, lastValidTime);
  }

  getRangeBeforeDate(duration: Duration, time: number, date: Date): string {
    let previousDate: Date;

    switch (duration) {
      case Duration.DAYS:
      case Duration.DAY:
        previousDate = dateFns.addDays(date, time);
        break;
      case Duration.WEEKS:
      case Duration.WEEK:
        previousDate = dateFns.addWeeks(date, time);
        break;
      case Duration.HOURS:
      case Duration.HOUR:
        previousDate = dateFns.addHours(date, time);
        break;
      case Duration.MINUTES:
        previousDate = dateFns.addMinutes(date, time);
        break;
      case Duration.MONTHS:
      case Duration.MONTH:
        previousDate = dateFns.addMonths(date, time);
        break;
      case Duration.YEARS:
      case Duration.YEAR:
        previousDate = dateFns.addYears(date, time);
        break;
      case Duration.SECONDS:
        previousDate = dateFns.addSeconds(date, time);
        break;
      default:
        throw new Error("Could not parse data");
    }

    return previousDate.toISOString();
  }

  parseReadableDateFormat(date: string, locale: string): string {
    return dateFns.parseISO(date).toLocaleDateString(locale);
  }

  parseReadableDateTimeFormat(
    date: string,
    locale: string,
    options?: DateTimeFormatOptions,
  ): string {
    if (!options) {
      options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hourCycle: "h23",
      };
    }

    return dateFns.parseISO(date).toLocaleDateString(locale, options);
  }

  toIsoFormat(date: string): string {
    return dateFns.parseISO(date).toISOString();
  }

  mergeDateWithHourUTC(date: string, hour: number): string {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Create a date object with the selected hour in the user's local time zone
    const localDateWithHour = new Date(date);
    localDateWithHour.setHours(hour, 0, 0, 0);

    // Convert this local time to UTC
    const utcDate = fromZonedTime(localDateWithHour, timeZone);

    return utcDate.toISOString();
  }

  endOfDayIso(date: string): string {
    const parsedDate = new Date(date);
    const endDate = dateFns.addSeconds(dateFns.endOfDay(parsedDate), -1);
    return endDate.toISOString();
  }

  startOfDayIso(date: string): string {
    const parsedDate = new Date(date);
    const startDate = dateFns.addSeconds(dateFns.startOfDay(parsedDate), 1);
    return startDate.toISOString();
  }

  convertSecondsToInterval(inputSeconds: number): dateFns.Duration {
    const startDate = new Date(0);

    const endDate = dateFns.addSeconds(startDate, inputSeconds);

    const duration = dateFns.intervalToDuration({
      start: startDate,
      end: endDate,
    });

    return {
      years: duration.years || 0,
      months: duration.months || 0,
      days: duration.days || 0,
      hours: duration.hours || 0,
      minutes: duration.minutes || 0,
      seconds: duration.seconds || 0,
    };
  }

  convertTimeToLocalReadable = (time: Date) => {
    //TODO: switch to dynamic zone for later
    const zonedDate = toZonedTime(time, "CET");
    return format(zonedDate, "yyyy-MM-dd HH:mm:ss", { timeZone: "CET" });
  };

  getDivisionDuration(duration: Duration): TimeSeriesRequestDurationEnum {
    switch (duration) {
      case Duration.HOURS:
        return TimeSeriesRequestDurationEnum.Hour;
      case Duration.DAYS:
        return TimeSeriesRequestDurationEnum.Hour;
      case Duration.WEEKS:
        return TimeSeriesRequestDurationEnum.Day;
      case Duration.MONTHS:
        return TimeSeriesRequestDurationEnum.Day;
      case Duration.YEARS:
        return TimeSeriesRequestDurationEnum.Week;
      default:
        throw new Error("Duration not implemented");
    }
  }

  getHour(date: string, options?: HourParsingOptions): string {
    if (!options) {
      options = { showMinutes: true, showSuffix: false };
    }
    const parsedDate = dateFns.parseISO(date);
    if (options.showMinutes) {
      return dateFns.format(parsedDate, "HH:mm");
    } else if (options.showSuffix) return dateFns.format(parsedDate, "H 'Uhr");
    return dateFns.format(parsedDate, "H");
  }

  getDifferenceInHours(start: string, end: string): number {
    const parsedStart = dateFns.parseISO(start);
    const parsedEnd = dateFns.parseISO(end);
    return dateFns.differenceInHours(parsedEnd, parsedStart);
  }

  getDurationFromDates(startDate: string, endDate: string) {
    const parsedStartDate = new Date(startDate);
    const startDateWithHour = dateFns.addSeconds(
      dateFns.startOfDay(parsedStartDate),
      1,
    );
    const parsedEndDate = new Date(endDate);
    const endDateWithHour = dateFns.addSeconds(
      dateFns.endOfDay(parsedEndDate),
      -1,
    );
    const differenceInDays =
      (endDateWithHour.getTime() - startDateWithHour.getTime()) /
      this.DAY_IN_MILLISECONDS;
    const duration =
      Math.abs(differenceInDays) < 60
        ? TimeSeriesRequestDurationEnum.Day
        : TimeSeriesRequestDurationEnum.Week;
    return duration;
  }

  getDifferenceInDays(startDate: Date, endDate: Date) {
    return Math.floor(
      (new Date(endDate).getTime() - new Date(startDate).getTime()) /
        this.DAY_IN_MILLISECONDS,
    );
  }

  getDatePartFromIso(date: string, format = "yyyy-MM-dd") {
    const parsedDate = dateFns.parseISO(date);
    return dateFns.format(parsedDate, format);
  }
}

export const dateService = new DateService();
