import { computed, WritableComputedRef } from "vue";
import { useI18n } from "vue-i18n";

const languages = computed(() => {
  const { availableLocales } = useI18n();

  return availableLocales.map((locale: string) => ({
    value: locale,
    text: `language.${locale}`,
  }));
});

const checkLocale = (locale: string) => {
  const { availableLocales } = useI18n();

  return availableLocales.some(item => item === locale);
};

const getBrowserLocale = () => {
  if (window.navigator.languages && window.navigator.languages[0]) {
    const locale = window.navigator.languages[0].split("-")[0];
    if (locale && checkLocale(locale)) {
      return locale;
    }
  }
  return null;
};

export const changeLocale = (
  newLocale: string,
  locale: WritableComputedRef<string>,
) => {
  locale.value = newLocale;
  localStorage.setItem("user-locale", newLocale);
};

export const useLocales = () => {
  const { locale } = useI18n();

  return {
    languages,
    locale,
    currentLanguage: computed(() => `language.${locale.value}`),
    browserLocale: computed(() => getBrowserLocale()),
  };
};
