<template>
  <svg viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1334_3593)">
      <path
        class="cls-1"
        d="M14 13.3247C17.6186 13.3247 20.5625 10.3808 20.5625 6.76221C20.5625 3.14364 17.6186 0.199707 14 0.199707C10.3814 0.199707 7.4375 3.14364 7.4375 6.76221C7.4375 10.3808 10.3814 13.3247 14 13.3247ZM14 1.94971C16.6534 1.94971 18.8125 4.10877 18.8125 6.76221C18.8125 9.41565 16.6534 11.5747 14 11.5747C11.3466 11.5747 9.1875 9.41565 9.1875 6.76221C9.1875 4.10877 11.3466 1.94971 14 1.94971Z"
        fill="#14005C"
      />
      <path
        class="cls-1"
        d="M18.3745 15.0747H9.62451C5.28232 15.0747 1.74951 18.6075 1.74951 22.9497V28.1997H26.2495V22.9497C26.2495 18.6075 22.7167 15.0747 18.3745 15.0747ZM24.4995 26.4497H3.49951V22.9497C3.49951 19.5722 6.24701 16.8247 9.62451 16.8247H18.3745C21.752 16.8247 24.4995 19.5722 24.4995 22.9497V26.4497Z"
        fill="#14005C"
      />
    </g>
    <defs>
      <clipPath id="clip0_1334_3593">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="translate(-0.000244141 0.199707)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.cls-1 {
  fill: #14005c;
}
</style>
