<template>
  <TheDropdown
    :label="label"
    :items="convertToReadableString(hours)"
    :item-title="'name'"
    :item-value="'_id'"
    :disabled="disabled"
    :default-value="defaultValue"
    :outlined="true"
    :rules="[mustNotBeNull, mustBePartOfSelected]"
    @change-item="changeHour"
  />
</template>

<script setup lang="ts">
import TheDropdown from "@/components/TheDropdown.vue";
import i18n from "@/plugins/i18n";

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  hours: {
    type: Array as () => number[],
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  defaultValue: {
    type: null,
    default: null,
  },
});

const emit = defineEmits(["change-hour"]);

const convertToReadableString = (values: number[]): string[] => {
  return values.map(e => {
    const hour = e < 10 ? "0" + e : e.toString();
    return hour + ":00";
  });
};

const changeHour = (payload: { label: string; value: string }) => {
  emit("change-hour", payload.value);
};

const mustNotBeNull = (v: string): string | boolean => {
  return (!!v && v.length !== 0) || (i18n.global.t("fieldRequired") as string);
};

const mustBePartOfSelected = (v: string): string | boolean => {
  if (!v || v.length === 0) return true;
  const initialValue = parseInt(v.split(":")[0]);
  const selected = props.hours.filter(e => e === initialValue).length != 0;

  return selected || (i18n.global.t("fieldRequired") as string);
};
</script>
