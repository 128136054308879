enum Duration {
  SECONDS = "seconds",
  MINUTES = "minutes",
  HOURS = "hours",
  HOUR = "hour",
  DAYS = "days",
  DAY = "day",
  WEEKS = "weeks",
  WEEK = "week",
  MONTHS = "months",
  MONTH = "month",
  YEARS = "years",
  YEAR = "year",
}

export default Duration;
