<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="150px"
    height="150px"
    viewBox="0 0 150 150"
    version="1.1"
  >
    <g id="surface1">
      <path
        class="cls-1"
        d="M 48.769531 88.5 L 48.769531 38.554688 C 48.769531 37.054688 47.898438 35.859375 46.808594 35.859375 C 45.730469 35.859375 44.859375 37.054688 44.859375 38.554688 L 44.859375 88.5 C 44.859375 90 45.730469 91.195312 46.808594 91.195312 C 47.898438 91.195312 48.769531 89.859375 48.769531 88.5 Z M 48.769531 88.5 "
      />
      <path
        class="cls-1"
        d="M 35.320312 88.5 L 35.320312 38.554688 C 35.320312 37.054688 34.449219 35.859375 33.359375 35.859375 C 32.285156 35.859375 31.414062 37.054688 31.414062 38.554688 L 31.414062 88.5 C 31.414062 90 32.285156 91.195312 33.359375 91.195312 C 34.449219 91.195312 35.320312 89.859375 35.320312 88.5 Z M 35.320312 88.5 "
      />
      <path
        class="cls-1"
        d="M 21.976562 88.5 L 21.976562 38.554688 C 21.976562 37.054688 21.105469 35.859375 20.03125 35.859375 C 18.9375 35.859375 18.085938 37.054688 18.085938 38.554688 L 18.085938 88.5 C 18.085938 90 18.9375 91.195312 20.03125 91.195312 C 21.105469 91.195312 21.976562 89.859375 21.976562 88.5 Z M 21.976562 88.5 "
      />
      <path
        class="cls-1"
        d="M 62.097656 88.5 L 62.097656 38.554688 C 62.097656 37.054688 61.226562 35.859375 60.152344 35.859375 C 59.058594 35.859375 58.191406 37.054688 58.191406 38.554688 L 58.191406 88.5 C 58.191406 90 59.058594 91.195312 60.152344 91.195312 C 61.226562 91.195312 62.097656 89.859375 62.097656 88.5 Z M 62.097656 88.5 "
      />
      <path
        class="cls-1"
        d="M 75.546875 88.5 L 75.546875 38.554688 C 75.546875 37.054688 74.675781 35.859375 73.582031 35.859375 C 72.507812 35.859375 71.636719 37.054688 71.636719 38.554688 L 71.636719 88.5 C 71.636719 90 72.507812 91.195312 73.582031 91.195312 C 74.675781 91.195312 75.546875 89.859375 75.546875 88.5 Z M 75.546875 88.5 "
      />
      <path
        class="cls-1"
        d="M 89.320312 93.140625 L 89.320312 32.554688 C 89.320312 28.945312 87.152344 25.945312 84.539062 25.945312 L 10.917969 25.945312 C 8.324219 25.945312 6.15625 28.945312 6.15625 32.554688 L 6.15625 45.140625 C 6.15625 46.640625 7.007812 47.859375 8.101562 47.859375 C 9.191406 47.859375 10.046875 46.640625 10.046875 45.140625 L 10.046875 32.554688 C 10.046875 31.804688 10.488281 31.195312 11.035156 31.195312 L 84.640625 31.195312 C 85.1875 31.195312 85.632812 31.804688 85.632812 32.554688 L 85.632812 93 C 85.632812 93.75 85.1875 94.359375 84.640625 94.359375 L 51.261719 94.359375 C 50.167969 94.359375 49.296875 95.554688 49.296875 97.054688 C 49.296875 98.554688 50.167969 99.75 51.261719 99.75 L 84.539062 99.75 C 87.152344 99.609375 89.320312 96.75 89.320312 93.140625 Z M 89.320312 93.140625 "
      />
      <path
        class="cls-1"
        d="M 23.496094 99.609375 C 24.585938 99.609375 25.457031 98.390625 25.457031 96.890625 C 25.457031 95.390625 24.585938 94.195312 23.496094 94.195312 L 10.917969 94.195312 C 10.371094 94.195312 9.945312 93.609375 9.945312 92.859375 L 9.945312 59.859375 C 9.945312 58.359375 9.074219 57.140625 8 57.140625 C 6.90625 57.140625 6.035156 58.359375 6.035156 59.859375 L 6.035156 92.859375 C 6.035156 96.445312 8.203125 99.445312 10.8125 99.445312 L 23.496094 99.445312 Z M 23.496094 99.609375 "
      />
      <path
        class="cls-1"
        d="M 19.058594 105.140625 L 9.621094 105.140625 C 8.527344 105.140625 7.675781 106.359375 7.675781 107.859375 C 7.675781 109.359375 8.527344 110.554688 9.621094 110.554688 L 19.058594 110.554688 C 20.132812 110.554688 21.003906 109.359375 21.003906 107.859375 C 21.003906 106.359375 20.132812 105.140625 19.058594 105.140625 Z M 19.058594 105.140625 "
      />
      <path
        class="cls-1"
        d="M 139.835938 54.445312 C 139.1875 37.5 135.175781 28.945312 132.90625 25.359375 C 131.8125 23.695312 130.414062 22.804688 128.894531 22.804688 L 104.386719 22.804688 C 103.296875 22.804688 102.425781 24 102.425781 25.5 C 102.425781 27 103.296875 28.195312 104.386719 28.195312 L 128.894531 28.195312 C 129.320312 28.195312 129.75 28.5 130.074219 28.945312 C 131.710938 31.359375 135.5 38.859375 136.046875 54.890625 C 136.265625 60.140625 136.148438 83.695312 136.046875 99.140625 C 136.046875 100.359375 136.046875 101.390625 136.046875 102.445312 C 132.90625 95.390625 127.152344 87.140625 117.835938 86.695312 C 108.074219 86.25 102.101562 92.554688 98.738281 98.25 L 98.738281 25.5 C 98.738281 24 97.886719 22.804688 96.792969 22.804688 C 95.703125 22.804688 95.050781 24 95.050781 25.5 L 95.050781 105.140625 L 54.503906 105.140625 C 53.429688 105.140625 52.558594 106.359375 52.558594 107.859375 C 52.558594 109.359375 53.429688 110.554688 54.503906 110.554688 L 95.050781 110.554688 C 95.378906 110.554688 95.820312 110.390625 96.144531 110.25 C 96.144531 110.25 96.246094 110.25 96.246094 110.390625 C 97.21875 110.859375 98.3125 110.109375 98.636719 108.75 C 98.859375 108 103.191406 91.195312 117.613281 91.945312 C 127.90625 92.390625 133.007812 104.554688 135.074219 111.609375 C 135.398438 112.945312 136.371094 113.695312 137.238281 113.695312 C 137.34375 113.695312 137.566406 113.695312 137.667969 113.695312 C 138.863281 113.390625 139.628906 112.054688 139.628906 110.390625 C 139.628906 107.554688 139.628906 103.640625 139.628906 99.304688 C 139.953125 82.5 140.054688 60 139.835938 54.445312 Z M 139.835938 54.445312 "
      />
      <path
        class="cls-1"
        d="M 105.464844 61.804688 L 131.0625 61.804688 C 132.785156 61.804688 134.203125 60 134.203125 57.890625 L 131.8125 37.5 C 131.710938 35.390625 130.292969 33.75 128.570312 33.75 L 105.582031 33.75 C 103.738281 33.75 102.324219 35.554688 102.324219 37.804688 L 102.324219 57.75 C 102.21875 60 103.621094 61.804688 105.464844 61.804688 Z M 106.007812 39 L 128.125 39 L 130.191406 56.554688 L 106.007812 56.554688 Z M 106.007812 39 "
      />
      <path
        class="cls-1"
        d="M 36.941406 96.609375 C 29.46875 96.609375 23.273438 105 23.273438 115.5 C 23.273438 125.859375 29.347656 134.390625 36.941406 134.390625 C 44.535156 134.390625 50.613281 125.859375 50.613281 115.5 C 50.613281 105.140625 44.417969 96.609375 36.941406 96.609375 Z M 36.941406 129.140625 C 31.515625 129.140625 27.078125 123 27.078125 115.5 C 27.078125 108 31.515625 101.859375 36.941406 101.859375 C 42.371094 101.859375 46.808594 108 46.808594 115.5 C 46.808594 123 42.371094 129.140625 36.941406 129.140625 Z M 36.941406 129.140625 "
      />
      <path
        class="cls-1"
        d="M 117.289062 96.609375 C 109.796875 96.609375 103.621094 105 103.621094 115.5 C 103.621094 125.859375 109.695312 134.390625 117.289062 134.390625 C 124.765625 134.390625 130.941406 126 130.941406 115.5 C 130.839844 105.140625 124.765625 96.609375 117.289062 96.609375 Z M 117.289062 129.140625 C 111.863281 129.140625 107.425781 123 107.425781 115.5 C 107.425781 108 111.863281 101.859375 117.289062 101.859375 C 122.699219 101.859375 127.152344 108 127.152344 115.5 C 127.152344 123 122.699219 129.140625 117.289062 129.140625 Z M 117.289062 129.140625 "
      />
    </g>
  </svg>
</template>

<script setup lang="ts"></script>

<style>
.cls-1 {
  fill: #14005c;
}
</style>
