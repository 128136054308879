<template>
  <div>
    <v-card
      class="card rounded-lg text--white"
      color="#00295b"
      elevation="2"
      rounded
    >
      <v-card-text>
        <v-row justify="center">
          <v-col cols="7">
            <span v-if="terminal" class="indicator-white">
              {{ terminal.name }}
            </span>
          </v-col>
          <v-col cols="10">
            <GetStatusBatchRequestForm
              :is-resubmit-batch-button-disabled="isResubmitBatchButtonDisabled"
              :terminal-id="terminal.id"
              @submit-csv="submitCSV"
            />
          </v-col>
          <v-col cols="10" class="text--white items">
            {{ $t("containersBeingProcessed") }}:
            <span class="indicator-white">
              {{ progressState.currentIndex }} / {{ lengthOfAllRequests }}
            </span>
          </v-col>
          <v-col cols="10" class="text--white items">
            {{ $t("foundContainers") }}:
            <span class="indicator-green">
              {{ progressState.successfulItems }}
            </span>
          </v-col>
          <v-col cols="10" class="text--white items">
            {{ $t("notFoundContainers") }}:
            <span class="indicator-red">{{ progressState.failedItems }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import QueueProgressIndicator from "@/views/forwarder-operator/get-status-batch/models/queue-progress-indicator";
import GetStatusBatchRequestForm from "@/views/forwarder-operator/get-status/GetStatusBatchRequestForm.vue";
import { Terminal } from "@/services/client/generated";
import { PropType } from "vue";

defineProps({
  progressState: {
    type: Object as PropType<QueueProgressIndicator>,
    default: () => ({}),
  },
  lengthOfAllRequests: {
    type: Number,
    required: true,
  },
  terminal: {
    type: Object as PropType<Terminal>,
    default: () => ({}),
  },
  isResubmitBatchButtonDisabled: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["submit-csv"]);

const submitCSV = (payload: { file: File }) => {
  emit("submit-csv", payload);
};
</script>

<style scoped lang="scss">
.items {
  color: white;
  font-size: 1rem;
}

$colorList:
  "white" white,
  "green" green,
  "red" red;

@each $i in $colorList {
  .indicator-#{nth($i,1)} {
    color: nth($i, 2);
    font-weight: bold;
    font-size: 1.3rem;
  }
}
</style>
