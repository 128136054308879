<template>
  <svg viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1455_1365)">
      <path
        d="M13.9999 12.9165C13.2883 12.9165 12.7166 13.4882 12.7166 14.1999C12.7166 14.9115 13.2883 15.4832 13.9999 15.4832C14.7116 15.4832 15.2833 14.9115 15.2833 14.1999C15.2833 13.4882 14.7116 12.9165 13.9999 12.9165ZM13.9999 2.5332C7.55992 2.5332 2.33325 7.75987 2.33325 14.1999C2.33325 20.6399 7.55992 25.8665 13.9999 25.8665C20.4399 25.8665 25.6666 20.6399 25.6666 14.1999C25.6666 7.75987 20.4399 2.5332 13.9999 2.5332ZM16.5549 16.7549L6.99992 21.1999L11.4449 11.6449L20.9999 7.19987L16.5549 16.7549Z"
        fill="#FDFDFD"
      />
    </g>
    <defs>
      <clipPath id="clip0_1455_1365">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(-0.000244141 0.199707)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.cls-1 {
  fill: #14005c;
}
</style>
