import {
  ParseGetStatusFileResponse,
  ParseGetStatusFileResponseErrorTypeEnum,
} from "@/services/client/generated";

export function parseGetStatusErrorMessageBasedOnType(
  response: ParseGetStatusFileResponse,
  t: (key: string) => string,
): { error: string; items: string[] } {
  if (!response.errorType) {
    throw new Error("Error type is required");
  }

  const errorTypeToTranslationKey: Record<string, string> = {
    [ParseGetStatusFileResponseErrorTypeEnum.ColumnMissing]: "column_missing",
    [ParseGetStatusFileResponseErrorTypeEnum.RowIncomplete]: "row_incomplete",
    [ParseGetStatusFileResponseErrorTypeEnum.DatatypeMismatch]:
      "datatype_mismatch",
    [ParseGetStatusFileResponseErrorTypeEnum.BookingTypeNotParseable]:
      "booking_type_not_parseable",
  };

  const mainText =
    errorTypeToTranslationKey[response.errorType.toString()] ?? "unknownError";
  const dynamicStrings: string[] =
    response.errorType !== ParseGetStatusFileResponseErrorTypeEnum.ColumnMissing
      ? [response.corruptRow?.toString() ?? t("unknownRow")]
      : (response.missingColumns?.map(e => e.toString()) ?? [
          t("unknownColumn"),
        ]);

  return { error: t(mainText), items: dynamicStrings };
}
