<template>
  <svg viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1461_1340)">
      <path
        d="M13.9883 2.73315C7.54825 2.73315 2.33325 7.95982 2.33325 14.3998C2.33325 20.8398 7.54825 26.0665 13.9883 26.0665C20.4399 26.0665 25.6666 20.8398 25.6666 14.3998C25.6666 7.95982 20.4399 2.73315 13.9883 2.73315ZM13.9999 23.7332C8.84325 23.7332 4.66659 19.5565 4.66659 14.3998C4.66659 9.24315 8.84325 5.06649 13.9999 5.06649C19.1566 5.06649 23.3333 9.24315 23.3333 14.3998C23.3333 19.5565 19.1566 23.7332 13.9999 23.7332ZM14.5833 8.56649H12.8333V15.5665L18.9583 19.2415L19.8333 17.8065L14.5833 14.6915V8.56649Z"
        fill="#FDFDFD"
      />
    </g>
    <defs>
      <clipPath id="clip0_1461_1340">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="translate(-0.000244141 0.399658)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped></style>
