import {
  ForwarderContainersApi,
  GetStatusRequest,
  GetStatusRequestResponseTO,
  GetStatusResponse,
  ParseGetStatusFileResponse,
  ParseGetStatusFileResponseErrorTypeEnum,
  Terminal,
} from "@/services/client/generated";
import { dateService, DateService } from "@/services/business/date-service";
import { Composer } from "vue-i18n";
import { downloadBlob } from "@/utils/http-utils";
import CsvParsingResponse from "@/internal-models/csv-parsing-response";
import SnackbarType from "@/store/interfaces/snackbar-type";
import { useAuthStore } from "@/store/useAuthStore";
import { useTerminalStore } from "@/store/useTerminalStore";
import { useForwarderStore } from "@/store/useForwarderStore";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import i18n from "@/plugins/i18n";
import { forwarderContainersClient } from "@/services/client/configs/services";

export class GetStatusBatchLogic {
  constructor(
    private authStore: ReturnType<typeof useAuthStore>,
    private terminalStore: ReturnType<typeof useTerminalStore>,
    private forwarderStore: ReturnType<typeof useForwarderStore>,
    private snackbarStore: ReturnType<typeof useSnackbarStore>,
    private dateService: DateService,
    private forwarderOperatorContainersService: ForwarderContainersApi,
    private vueI18n: Composer,
  ) {}

  async getStatus(
    terminalId: number,
    getStatusRequest: GetStatusRequest,
  ): Promise<GetStatusResponse> {
    const userId = this.authStore.authUser?.userId;
    const forwarderId = this.forwarderStore.getForwarder()?.id;

    if (!userId || !forwarderId) {
      throw new Error("User ID or Forwarder ID not found");
    }

    const response = await this.forwarderOperatorContainersService.getStatus1(
      userId,
      forwarderId,
      terminalId,
      getStatusRequest,
      "list_get_status",
    );
    return response.data;
  }

  async getTerminalById(terminalId: number): Promise<Terminal | undefined> {
    const userId = this.authStore.authUser?.userId;

    if (!userId) {
      throw new Error("User ID not found");
    }

    const terminals = await this.terminalStore.getTerminals(userId);

    if (!terminals) {
      throw new Error("Terminals not found");
    }

    return terminals.find(terminal => terminal.id === terminalId);
  }

  async getStatusFileCallParameters(
    file: File,
  ): Promise<CsvParsingResponse<GetStatusRequest>> {
    const userId = this.authStore.authUser?.userId;
    const forwarderId = this.forwarderStore.getForwarder()?.id;

    if (!userId || !forwarderId) {
      throw new Error("User ID or Forwarder ID not found");
    }

    const response = (
      await this.forwarderOperatorContainersService.parseGetStatusFile(
        userId,
        forwarderId,
        file,
      )
    ).data;

    if (response.errorType) {
      const parsedError = this.parseGetStatusErrorMessageBasedOnType(response);
      return {
        error: this.vueI18n.t(parsedError.error, [
          parsedError.items.map(e => this.vueI18n.t(e)).join(","),
        ]) as string,
        failed: true,
        items: [],
      };
    }

    if (!response.getStatusRequests) {
      throw new Error("No Status Requests found in response");
    }

    return {
      error: "",
      failed: false,
      items: response.getStatusRequests,
    };
  }

  parseGetStatusErrorMessageBasedOnType(response: ParseGetStatusFileResponse): {
    error: string;
    items: string[];
  } {
    if (!response.errorType) {
      throw new Error("Invalid response: missing error type");
    }
    const mainText = response.errorType?.toString() ?? "unknownError";
    const dynamicStrings: string[] =
      mainText !== ParseGetStatusFileResponseErrorTypeEnum.ColumnMissing
        ? [response.corruptRow?.toString() ?? ""]
        : (response.missingColumns?.map(e => e.toString()) ?? []);

    return { error: mainText, items: dynamicStrings };
  }

  async downloadCSV(
    terminalId: number,
    groupedData: GetStatusRequestResponseTO[],
    fileName: string,
  ): Promise<void> {
    const userId = this.authStore.authUser?.userId;
    const forwarderId = this.forwarderStore.getForwarder()?.id;

    if (!userId || !forwarderId) {
      throw new Error("User ID or Forwarder ID not found");
    }

    const nameWithoutSuffix = fileName.split(".").slice(0, -1).join(".");
    const response =
      await this.forwarderOperatorContainersService.downloadGetStatusCsv(
        userId,
        forwarderId,
        {
          getStatusRequestResponses: groupedData,
          terminalId: terminalId,
        },
      );

    // byte mark character added to specify endian order, needed for correct excel file format
    downloadBlob(
      ("\ufeff" + response.data) as unknown as string,
      `${nameWithoutSuffix}_download.csv`,
      "text/csv;charset=UTF-8",
    );
  }

  async showGetStatusError(errorText: string): Promise<void> {
    await this.snackbarStore.showSnackbar({
      text: errorText,
      snackbarType: SnackbarType.ERROR,
    });
  }
}

let viewModel: GetStatusBatchLogic | null = null;

export const getViewModel = (): GetStatusBatchLogic => {
  if (!viewModel) {
    viewModel = new GetStatusBatchLogic(
      useAuthStore(),
      useTerminalStore(),
      useForwarderStore(),
      useSnackbarStore(),
      dateService,
      forwarderContainersClient,
      i18n.global as Composer,
    );
  }
  return viewModel;
};
