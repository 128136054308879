export const ACTIVE_COLUMNS = [
  { title: "type", key: "type", value: "type", sortable: true },
  {
    title: "referenceNumber",
    key: "referenceNumber",
    value: "referenceNumber",
    sortable: false,
  },
  {
    title: "trainOperatorBookings.containerNumber",
    key: "trainOperatorBookings.containerNumber",
    value: "trainOperatorBookings.containerNumber",
    sortable: false,
  },
  {
    title: "containerLength",
    key: "containerLength",
    value: "containerLength",
    sortable: true,
  },
  { title: "netWeight", key: "netWeight", value: "netWeight", sortable: true },
  {
    title: "emptyWeight",
    key: "emptyWeight",
    value: "emptyWeight",
    sortable: true,
  },
  { title: "forwarder", key: "forwarder", value: "forwarder", sortable: true },
  { title: "driver", key: "driver", value: "driver", sortable: true },
  {
    title: "licensePlate",
    key: "licensePlate",
    value: "licensePlate",
    sortable: true,
  },
  { title: "slotTime", key: "slotTime", value: "slotTime", sortable: false },
  {
    title: "trainOperatorBookings.lastLoadingTime",
    key: "trainOperatorBookings.lastLoadingTime",
    value: "trainOperatorBookings.lastLoadingTime",
    sortable: false,
  },
  {
    title: "trainOperatorBookings.trainNumber",
    key: "trainOperatorBookings.trainNumber",
    value: "trainOperatorBookings.trainNumber",
    sortable: true,
  },
];

export const COMPLETED_COLUMNS = [
  { title: "", key: "select", value: "select", sortable: false },
  { title: "type", key: "type", value: "type", sortable: true },
  {
    title: "referenceNumber",
    key: "referenceNumber",
    value: "referenceNumber",
    sortable: false,
  },
  {
    title: "trainOperatorBookings.containerNumber",
    key: "trainOperatorBookings.containerNumber",
    value: "trainOperatorBookings.containerNumber",
    sortable: false,
  },
  {
    title: "containerLength",
    key: "containerLength",
    value: "containerLength",
    sortable: true,
  },
  { title: "netWeight", key: "netWeight", value: "netWeight", sortable: true },
  {
    title: "emptyWeight",
    key: "emptyWeight",
    value: "emptyWeight",
    sortable: true,
  },
  { title: "forwarder", key: "forwarder", value: "forwarder", sortable: true },
  { title: "driver", key: "driver", value: "driver", sortable: true },
  {
    title: "licensePlate",
    key: "licensePlate",
    value: "licensePlate",
    sortable: true,
  },
  { title: "slotTime", key: "slotTime", value: "slotTime", sortable: false },
  {
    title: "trainOperatorBookings.lastLoadingTime",
    key: "trainOperatorBookings.lastLoadingTime",
    value: "trainOperatorBookings.lastLoadingTime",
    sortable: false,
  },
  {
    title: "transhipmentDate",
    key: "transhipmentDate",
    value: "transhipmentDate",
    sortable: false,
  },
  {
    title: "trainOperatorBookings.trainNumber",
    key: "trainOperatorBookings.trainNumber",
    value: "trainOperatorBookings.trainNumber",
    sortable: true,
  },
];
