import PageCardItem from "@/internal-models/page-card-item";
import IconAssets from "@/constants/assets/icon-assets";

const getTerminalOperatorPageCards = (): Record<string, PageCardItem> => {
  return {
    blocklist: {
      icon: IconAssets.BLOCK_USER,
      background: "terminals",
      title: "blockList.title",
      visible: true,
      page: "/terminalOperator/blockList",
    },
    deadlines: {
      icon: IconAssets.ACCEPTANCE_DEADLINE,
      background: "operator-chair",
      title: "deadlines",
      visible: true,
      page: "/terminalOperator/deadlines",
    },
    modulemanagment: {
      icon: IconAssets.MENU,
      background: "graph",
      title: "moduleManagment",
      visible: true,
      page: "/terminalOperator/modulemanagment",
    },
  };
};

export default getTerminalOperatorPageCards;
