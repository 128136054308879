import { TerminalUsersApi } from "@/services/client/generated";
import SnackbarType from "@/store/interfaces/snackbar-type";
import i18n from "@/plugins/i18n";
import { useAuthStore } from "@/store/useAuthStore";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import { terimnalUserClient } from "@/services/client/configs/services";

export class LoginPageLogic {
  constructor(
    private authStore: ReturnType<typeof useAuthStore>,
    private snackbarStore: ReturnType<typeof useSnackbarStore>,
    private terimnalUserClient: TerminalUsersApi,
  ) {}

  async login(email: string, password: string): Promise<void> {
    //TODO: check on why we have a null value
    await this.authStore.login({ email, password });
  }

  async logout(): Promise<void> {
    await this.authStore.logout();
  }

  async resetPassword(email: string): Promise<void> {
    await this.terimnalUserClient.resetPassword({ email });
  }

  async showEmailSentSnackbar(): Promise<void> {
    await this.snackbarStore.showSnackbar({
      text: i18n.global.t("emailSent"),
      snackbarType: SnackbarType.SUCCESS,
    });
  }
}

let viewModel: LoginPageLogic | null = null;

export const getViewModel = (): LoginPageLogic => {
  if (!viewModel) {
    viewModel = new LoginPageLogic(
      useAuthStore(),
      useSnackbarStore(),
      terimnalUserClient,
    );
  }
  return viewModel;
};
