export function downloadBlob(data: string, name: string, type: string) {
  const file = window.URL.createObjectURL(new Blob([data], { type }));

  const docUrl = document.createElement("a");
  docUrl.setAttribute("href", file);
  docUrl.setAttribute("download", name);
  document.body.appendChild(docUrl);
  docUrl.click();

  setTimeout(() => {
    document.body.removeChild(docUrl);
    window.URL.revokeObjectURL(file);
  }, 100);
}
