import { dateService, DateService } from "@/services/business/date-service";
import Duration from "@/internal-models/enums/duration";
import { getStatisticTypeFromStringForTerminal } from "@/utils/statistic-utils";
import {
  StatisticsApi,
  TimeSeriesRequestNameEnum,
  TimeSeriesResponse,
  RangedTerminalStatisticsValueRequest,
  RangedTerminalStatisticsValueResponse,
} from "@/services/client/generated";

import { useAuthStore } from "@/store/useAuthStore";
import { useTerminalStore } from "@/store/useTerminalStore";
import TimeRange from "@/internal-models/time-range";
import { Composer } from "vue-i18n";
import i18n from "@/plugins/i18n";
import { statisticsClient } from "@/services/client/configs/services";

export class TerminalDashboardLogic {
  constructor(
    private authStore: ReturnType<typeof useAuthStore>,
    private terminalStore: ReturnType<typeof useTerminalStore>,
    private dateService: DateService,
    private statisticsService: StatisticsApi,
    private vueI18n: Composer,
  ) {}

  getMinutesForStatistic(time: number): string {
    const { minutes = 0, seconds = 0 } =
      this.dateService.convertSecondsToInterval(time);
    const addZeroIfNone = (value: number): string =>
      value < 10 ? "0" + value.toString() : value.toString();
    return `${addZeroIfNone(minutes)}:${addZeroIfNone(seconds)}`;
  }

  areDatesCorrect(acceptanceDates: Date[]): string | boolean {
    if (acceptanceDates.length !== 2)
      return this.vueI18n.t("pickBothDates") as string;
    return (
      this.dateService.isBeforeDate(acceptanceDates[0], acceptanceDates[1]) ||
      this.dateService.isEqual(acceptanceDates[0], acceptanceDates[1])
    );
  }

  async fetchTimeSeriesChartData(
    range: TimeRange,
  ): Promise<TimeSeriesResponse> {
    const userId: string = this.authStore.authUser?.userId ?? "";
    const terminal = this.terminalStore.getTerminal();
    const terminalId: number = terminal?.id ?? 0;

    if (!userId || !terminalId) {
      throw new Error("User ID or Terminal ID not found");
    }

    const date = new Date();
    const response = await this.statisticsService.getTimeSeries(
      userId,
      terminalId,
      {
        name: TimeSeriesRequestNameEnum.CompletedBookings,
        seriesStart: this.dateService.getRangeBeforeDate(
          range.duration,
          -range.amount,
          date,
        ),
        seriesEnd: this.dateService.toIsoFormat(new Date().toISOString()),
        duration: this.dateService.getDivisionDuration(range.duration),
      },
    );

    return response.data;
  }

  async fetchTimeSeriesChartDataForCustomRange(
    startDate: string,
    endDate: string,
  ): Promise<TimeSeriesResponse> {
    const userId: string = this.authStore.authUser?.userId ?? "";
    const terminal = this.terminalStore.getTerminal();
    const terminalId: number = terminal?.id ?? 0;

    if (!userId || !terminalId) {
      throw new Error("User ID or Terminal ID not found");
    }

    const response = await this.statisticsService.getTimeSeries(
      userId,
      terminalId,
      {
        name: TimeSeriesRequestNameEnum.CompletedBookings,
        seriesStart: this.dateService.startOfDayIso(startDate),
        seriesEnd: this.dateService.endOfDayIso(endDate),
        duration: this.dateService.getDurationFromDates(startDate, endDate),
      },
    );

    return response.data;
  }

  dateNotInFuture(date: Date): boolean | string {
    return (
      !this.dateService.isInFuture(date) ||
      (this.vueI18n.t("dateNotFuture") as string)
    );
  }

  async fetchDataForStatisticBox(
    requestData: RangedTerminalStatisticsValueRequest,
  ): Promise<RangedTerminalStatisticsValueResponse> {
    const userId: string = this.authStore.authUser?.userId ?? "";
    const terminal = this.terminalStore.getTerminal();
    const terminalId: number = terminal?.id ?? 0;

    if (!userId || !terminalId) {
      throw new Error("User ID or Terminal ID not found");
    }

    const statisticFloatValue =
      await this.statisticsService.getStatisticFloatValue(
        userId,
        terminalId,
        requestData,
      );
    return statisticFloatValue.data;
  }

  pickFloatStatistic(
    duration: Duration,
    amount: number,
    statisticType: string,
  ): RangedTerminalStatisticsValueRequest {
    const date = new Date();
    if (duration !== Duration.DAYS) {
      const startTime = this.dateService.getRangeBeforeDate(
        duration,
        -amount,
        date,
      );
      const endTime = this.dateService.toIsoFormat(new Date().toISOString());
      return {
        start: this.dateService.startOfDayIso(startTime),
        end: this.dateService.endOfDayIso(endTime),
        name: getStatisticTypeFromStringForTerminal(statisticType),
      };
    } else {
      return {
        start: this.dateService.startOfDayIso(date.toISOString()),
        end: this.dateService.endOfDayIso(date.toISOString()),
        name: getStatisticTypeFromStringForTerminal(statisticType),
      };
    }
  }

  parseDate(date: string): string {
    if (!date) return "";
    return this.dateService.parseReadableDateFormat(
      date,
      this.vueI18n.locale.value,
    );
  }

  validRange(startDate: Date, endDate: Date): string | boolean {
    const differenceInDays = this.dateService.getDifferenceInDays(
      startDate,
      endDate,
    );

    if (differenceInDays > this.dateService.TWO_YEARS_IN_DAYS) {
      return this.vueI18n.t("notMoreThanTwoYears") as string;
    }

    return (
      differenceInDays > 0 &&
      differenceInDays <= this.dateService.TWO_YEARS_IN_DAYS
    );
  }
}

let viewModel: TerminalDashboardLogic | null = null;

export const getViewModel = (): TerminalDashboardLogic => {
  if (!viewModel) {
    viewModel = new TerminalDashboardLogic(
      useAuthStore(),
      useTerminalStore(),
      dateService,
      statisticsClient,
      i18n.global as Composer,
    );
  }
  return viewModel;
};
