const IconAssets: Record<string, string> = {
  //material icons
  ACCEPTANCE_DEADLINE: "mdi-view-dashboard",
  HEADPHONES: "mdi-headphones-box",
  MENU: "mdi-menu",
  LOGOUT: "mdi-logout",
  SUCCESS: " mdi-checkbox-marked-circle",
  WARNING: "mdi-alert",
  ERROR: "mdi-alert-circle",
  ACCOUNT: "mdi-account",
  HOME_ICON: "mdi-home",
  BLOCK_USER: "mdi-account-cancel",

  //svg icons
  HOME: "HomeIcon",
  CONTAINER: "ContainerIcon",
  GATE: "GateIcon",
  GATE_ACTIVE: "GateWhiteIcon",
  TRAIN: "TrainIcon",
  TRAIN_ACTIVE: "TrainWhiteIcon",
  TRUCK: "TruckIcon",
  TRUCK_ACTIVE: "TruckWhiteIcon",
  ORDERS_FILLED: "OrdersFilled",
  ORDERS_OUTLINED: "OrdersOutlined",
  COMPASS_OUTLINED: "CompassOutlined",
  COMPASS_FILLED: "CompassFilled",
  DISTURB_OUTLINED: "DisturbOutlined",
  DISTURB_FILLED: "DisturbFilled",
  BLOCK_OUTLINED: "BlockOutlined",
  BLOCK_FILLED: "BlockFilled",
  TIME_OUTLINED: "TimeOutlined",
  TIME_FILLED: "TimeFilled",
  USER_OUTLINED: "UserOutlined",
  USER_FILLED: "UserFilled",
};

export default IconAssets;
