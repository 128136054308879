import PageCardItem from "@/internal-models/page-card-item";
import IconAssets from "@/constants/assets/icon-assets";

const getForwarderOperatorPageCards = (): Record<string, PageCardItem> => {
  return {
    statusRequest: {
      icon: IconAssets.HOME_ICON,
      background: "terminals",
      title: "statusRequest",
      visible: true,
      page: "/forwarderOperator/statusRequest",
    },
    truckerManagement: {
      icon: IconAssets.MENU,
      background: "operator-chair",
      title: "truckerManagement",
      visible: true,
      page: "/forwarderOperator/truckerManagement",
    },
    analyticalDashboard: {
      icon: IconAssets.ACCEPTANCE_DEADLINE,
      background: "graph",
      title: "analyticalDashboard",
      visible: true,
      page: "/forwarderOperator/analyticalDashboard",
    },
    bookingsOverview: {
      icon: IconAssets.HEADPHONES,
      background: "trucks",
      title: "bookingsOverview",
      visible: true,
      page: "/forwarderOperator/bookingsOverview",
    },
  };
};

export default getForwarderOperatorPageCards;
