import { dateService, DateService } from "@/services/business/date-service";
import { useAuthStore } from "@/store/useAuthStore";
import { useForwarderStore } from "@/store/useForwarderStore";
import { useForwarderOperatorStore } from "@/store/useForwarderOperatorStore";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import {
  BookingRequest,
  BookingRequestTypeEnum,
  ForwarderBookingsApi,
  ForwarderContainersApi,
  ForwarderOperatorTerminalApiApi,
  ForwarderTruckersApiApi,
  GetStatusRequest,
  GetStatusRequestTypeEnum,
  GetStatusResponse,
  OneToOneRestriction,
  Terminal,
  TimeSlot,
  TruckerForForwarderTO,
} from "@/services/client/generated";

import SnackbarType from "@/store/interfaces/snackbar-type";
import CsvParsingResponse from "@/internal-models/csv-parsing-response";
import { parseGetStatusErrorMessageBasedOnType } from "@/views/forwarder-operator/get-status/get-status-helper";
import { Composer } from "vue-i18n";
import { GetStatusFormPreferences } from "@/store/interfaces/user-role-config";
import i18n from "@/plugins/i18n";
import {
  forwarderBookingsClient,
  forwarderContainersClient,
  forwarderOperatorTerminalClient,
  forwarderTruckersClient,
} from "@/services/client/configs/services";

class EntityNotFoundError extends Error {
  constructor(entity: string) {
    super(`${entity} not found`);
    this.name = "EntityNotFoundError";
  }
}

export class GetStatusLogic {
  constructor(
    private authStore: ReturnType<typeof useAuthStore>,
    private forwarderStore: ReturnType<typeof useForwarderStore>,
    private forwarderOperatorConfigStore: ReturnType<
      typeof useForwarderOperatorStore
    >,
    private snackbarStore: ReturnType<typeof useSnackbarStore>,
    private dateService: DateService,
    private forwarderBookingsService: ForwarderBookingsApi,
    private forwarderOperatorContainersService: ForwarderContainersApi,
    private forwarderTruckersService: ForwarderTruckersApiApi,
    private forwarderOperatorTerminalService: ForwarderOperatorTerminalApiApi,
    private vueI18n: Composer,
  ) {}

  async getUserGetStatusPreference(): Promise<
    GetStatusFormPreferences | undefined
  > {
    return this.forwarderOperatorConfigStore.getGetStatusPreferences();
  }

  async saveGetStatusPreference(
    preference: GetStatusFormPreferences,
  ): Promise<void> {
    this.forwarderOperatorConfigStore.rememberGetStatusPreferences(preference);
  }

  async getStatus(
    getStatusRequest: GetStatusRequest,
  ): Promise<GetStatusResponse> {
    const userId = this.authStore.authUser?.userId;
    const forwarder = this.forwarderStore.getForwarder();

    if (!userId) throw new EntityNotFoundError("User");
    if (!forwarder) throw new EntityNotFoundError("Forwarder");
    if (!getStatusRequest.terminalId)
      throw new EntityNotFoundError("Terminal ID");

    const forwarderId = forwarder.id;

    const response = await this.forwarderOperatorContainersService.getStatus1(
      userId,
      forwarderId,
      getStatusRequest.terminalId,
      getStatusRequest,
      "single_get_status",
    );
    return response.data;
  }

  async getTerminalsForDropdown(): Promise<Terminal[]> {
    const userId = this.authStore.authUser?.userId;
    const forwarder = this.forwarderStore.getForwarder();

    if (!forwarder) throw new EntityNotFoundError("Forwarder");
    if (!userId) throw new EntityNotFoundError("User");

    const forwarderId = forwarder.id;

    return (
      await this.forwarderOperatorTerminalService.getTerminalsForForwarderOperator(
        userId,
        forwarderId,
      )
    ).data;
  }

  getDayOfTimeSlot(timeSlot: TimeSlot, locale: string): string {
    return this.dateService.parseReadableDateFormat(timeSlot.slotBegin, locale);
  }

  getHoursOfTimeSlot(timeSlot: TimeSlot): string {
    return `${this.dateService.getHour(timeSlot.slotBegin)} - ${this.dateService.getHour(timeSlot.slotEnd)}`;
  }

  async getTruckersForForwarder(): Promise<TruckerForForwarderTO[]> {
    const userId = this.authStore.authUser?.userId;
    const forwarder = this.forwarderStore.getForwarder();

    if (!forwarder) throw new EntityNotFoundError("Forwarder");
    if (!userId) throw new EntityNotFoundError("User");

    const forwarderId = forwarder.id;

    const response = await this.forwarderTruckersService.getAllApprovedTruckers(
      userId,
      forwarderId,
    );
    return response.data;
  }

  async getStatusFileCallParameters(
    file: File,
  ): Promise<CsvParsingResponse<GetStatusRequest>> {
    const userId = this.authStore.authUser?.userId;
    const forwarder = this.forwarderStore.getForwarder();

    if (!forwarder) throw new EntityNotFoundError("Forwarder");
    if (!userId) throw new EntityNotFoundError("User");

    const forwarderId = forwarder.id;

    const response = (
      await this.forwarderOperatorContainersService.parseGetStatusFile(
        userId,
        forwarderId,
        file,
      )
    ).data;

    if (response.errorType) {
      const parsedError = parseGetStatusErrorMessageBasedOnType(
        response,
        this.vueI18n.t,
      );
      return {
        error: this.vueI18n.t(parsedError.error, [
          parsedError.items.map(e => this.vueI18n.t(e)).join(","),
        ]) as string,
        failed: true,
        items: [],
      };
    }
    if (!response.getStatusRequests) {
      throw new EntityNotFoundError("Status requests");
    }

    return {
      error: "",
      failed: false,
      items: response.getStatusRequests,
    };
  }

  async createBooking(
    creationText: string,
    statusRequest: GetStatusRequest,
    statusResponse: GetStatusResponse,
    trucker: TruckerForForwarderTO,
    timeSlot: TimeSlot,
    terminalId: number,
  ): Promise<void> {
    const forwarder = this.forwarderStore.getForwarder();
    if (!forwarder) throw new EntityNotFoundError("Forwarder");
    if (!trucker.licensePlate) throw new EntityNotFoundError("License Plate");

    const bookingRequest: BookingRequest = {
      containerNumber: statusResponse?.container?.containerNumber,
      referenceNumber: statusResponse?.referenceNumber,
      licensePlate: trucker.licensePlate,
      type:
        statusRequest.type == GetStatusRequestTypeEnum.Pickup
          ? BookingRequestTypeEnum.Pickup
          : BookingRequestTypeEnum.Delivery,
      timeSlot: timeSlot,
      terminalId: terminalId,
      forwarder: forwarder.name,
    };
    const userId = this.authStore.authUser?.userId;
    if (!userId) throw new EntityNotFoundError("User");

    const forwarderId = forwarder.id;
    if (!trucker.userId) throw new EntityNotFoundError("Trucker ID");

    await this.forwarderBookingsService.createBookingForTrucker(
      userId,
      forwarderId,
      trucker.userId,
      bookingRequest,
    );
    await this.snackbarStore.showSnackbar({
      text: creationText,
      snackbarType: SnackbarType.SUCCESS,
    });
  }

  async showGetStatusError(errorText: string): Promise<void> {
    await this.snackbarStore.showSnackbar({
      text: errorText,
      snackbarType: SnackbarType.ERROR,
    });
  }

  async getOneToOneRestrictions(terminalId: number) {
    const userId = this.authStore.authUser?.userId;
    const forwarder = this.forwarderStore.getForwarder();

    if (!userId) {
      throw new EntityNotFoundError("User ID");
    }

    if (!forwarder) {
      throw new EntityNotFoundError("Forwarder ID");
    }

    const forwarderId = forwarder.id;

    const response =
      await this.forwarderOperatorTerminalService.getOneToOneRestrictionsForForwarderOperatorForTerminal(
        userId,
        forwarderId,
        terminalId,
      );
    return response.data;
  }

  parseOneToOneRestrictionRange(
    restriction: OneToOneRestriction,
    locale: string,
  ): string {
    const restrictionStart = restriction.restrictionStart;
    const restrictionEnd = restriction.restrictionEnd;
    const parsedFirstDate = this.dateService.parseReadableDateTimeFormat(
      restrictionStart,
      locale,
    );
    const timeOfLastRestriction = this.dateService.getHour(restrictionEnd);
    return `${parsedFirstDate}-${timeOfLastRestriction}`;
  }

  parseOneToOneRestrictionMap(
    oneToOneRestrictions: OneToOneRestriction[],
    locale: string,
  ) {
    const map: Record<string, { id: number | undefined; time: string }[]> = {};
    for (let i = 0; i < oneToOneRestrictions.length; i++) {
      const start = new Date(oneToOneRestrictions[i].restrictionStart);
      const end = new Date(oneToOneRestrictions[i].restrictionEnd);
      const startDate = this.dateService.parseReadableDateFormat(
        oneToOneRestrictions[i].restrictionStart,
        locale,
      );
      const time = `${this.dateService.getHour(start.toISOString())} - ${this.dateService.getHour(end.toISOString())}`;
      if (map[startDate]) {
        map[startDate].push({ id: oneToOneRestrictions[i].id, time });
      } else {
        map[startDate] = [{ id: oneToOneRestrictions[i].id, time }];
      }
    }
    return map;
  }
}

let viewModel: GetStatusLogic | null = null;

export const getViewModel = (): GetStatusLogic => {
  if (!viewModel) {
    viewModel = new GetStatusLogic(
      useAuthStore(),
      useForwarderStore(),
      useForwarderOperatorStore(),
      useSnackbarStore(),
      dateService,
      forwarderBookingsClient,
      forwarderContainersClient,
      forwarderTruckersClient,
      forwarderOperatorTerminalClient,
      i18n.global as Composer,
    );
  }

  return viewModel;
};
