import { webappStatiscticsForwarderClient } from "@/services/client/configs/services";
import {
  ConstantForwarderStatisticFloatValueResponseTO,
  ConstantForwarderStatisticValueRequestTO,
  WebappStatisticsForForwarderApi,
} from "@/services/client/generated";

import { useAuthStore } from "@/store/useAuthStore";
import { useForwarderStore } from "@/store/useForwarderStore";

import { getStatisticTypeFromStringForForwarder } from "@/utils/statistic-utils";

export class ForwarderDashboardLogic {
  constructor(
    private authStore: ReturnType<typeof useAuthStore>,
    private forwarderStore: ReturnType<typeof useForwarderStore>,
    private forwarderStatisticsService: WebappStatisticsForForwarderApi,
  ) {}

  async fetchDataForStatisticBox(
    requestData: ConstantForwarderStatisticValueRequestTO,
  ): Promise<ConstantForwarderStatisticFloatValueResponseTO> {
    const userId = this.authStore.authUser?.userId;
    const forwarder = this.forwarderStore.getForwarder();
    const forwarderId = forwarder ? forwarder.id : undefined;

    if (!userId || !forwarderId) {
      throw new Error("User or forwarder not found");
    }

    const statisticFloatValue =
      await this.forwarderStatisticsService.getStatisticFloatValue1(
        userId,
        forwarderId,
        requestData,
      );

    return statisticFloatValue.data;
  }

  pickFloatStatistic(
    statisticType: string,
  ): ConstantForwarderStatisticValueRequestTO {
    return {
      name: getStatisticTypeFromStringForForwarder(statisticType),
    };
  }
}

let viewModel: ForwarderDashboardLogic | null = null;

export const getViewModel = (): ForwarderDashboardLogic => {
  if (!viewModel) {
    viewModel = new ForwarderDashboardLogic(
      useAuthStore(),
      useForwarderStore(),
      webappStatiscticsForwarderClient,
    );
  }

  return viewModel;
};
