import {
  AcceptanceDeadline,
  AcceptanceRestrictionsApi,
  OneToOneRestriction,
} from "@/services/client/generated";
import { dateService, DateService } from "@/services/business/date-service";
import SnackbarType from "@/store/interfaces/snackbar-type";
import i18n from "@/plugins/i18n";
import { Composer } from "vue-i18n";
import { useAuthStore } from "@/store/useAuthStore";
import { useTerminalStore } from "@/store/useTerminalStore";
import { useSnackbarStore } from "@/store/useSnackbarStore";
import { acceptanceRestrictionsClient } from "@/services/client/configs/services";

const { t } = i18n.global;

//TODO: inversion
export class AcceptanceRestrictionLogic {
  constructor(
    private acceptanceRestrictionService: AcceptanceRestrictionsApi,
    private dateService: DateService,
    private vueI18n: Composer,
    private authStore = useAuthStore(),
    private terminalStore = useTerminalStore(),
    private snackbarStore = useSnackbarStore(),
  ) {}

  getOrderedAcceptanceDates(acceptanceDates: Date[]): Date[] {
    const isBeforeDate = (firstDate: Date, secondDate: Date) =>
      this.dateService.isBeforeDate(firstDate, secondDate) === true ? -1 : 1;
    return acceptanceDates.sort(isBeforeDate);
  }

  async getAcceptanceDeadlines(): Promise<AcceptanceDeadline[]> {
    const userId = this.authStore.authUser?.userId ?? "";
    const terminalId = this.terminalStore.getTerminal()?.id ?? 0;
    const response =
      await this.acceptanceRestrictionService.getAcceptanceDeadlinesForTerminal(
        userId,
        terminalId,
      );
    return response.data;
  }

  async getOneToOneRestrictions(): Promise<OneToOneRestriction[]> {
    const userId = this.authStore.authUser?.userId ?? "";
    const terminalId = this.terminalStore.getTerminal()?.id ?? 0;
    const response =
      await this.acceptanceRestrictionService.getOneToOneRestrictionsForTerminal(
        userId,
        terminalId,
      );
    return response.data;
  }

  isAcceptanceDateInPresent(departureDate: Date | null): string | boolean {
    if (departureDate === null || !departureDate) {
      return t("fieldRequired") as string;
    }

    return (
      !this.dateService.isInPast(departureDate) || (t("dateNotPast") as string)
    );
  }

  isAcceptanceDeadlineCorrect(acceptanceDates: Date[]): string | boolean {
    if (acceptanceDates.length < 1) return t("pickBothDates") as string;
    return (
      !(
        this.dateService.isInPast(acceptanceDates[0]) ||
        this.dateService.isInPast(acceptanceDates[acceptanceDates.length - 1])
      ) || (t("dateNotPast") as string)
    );
  }

  isOneToOneRestrictionCorrect(acceptanceDate: Date | null): string | boolean {
    if (!acceptanceDate) return t("fieldRequired") as string;

    return (
      !this.dateService.isInPast(acceptanceDate) || (t("dateNotPast") as string)
    );
  }

  async addAcceptanceDeadline(
    acceptanceDates: Date[],
    departureDate: string,
    trainNumber: string,
  ): Promise<void> {
    const activeDeadline: AcceptanceDeadline = {
      deadlineStart: "",
      deadlineEnd: "",
      terminalId: -1,
    };
    const userId = this.authStore.authUser?.userId ?? "";
    const terminalId = this.terminalStore.getTerminal()?.id ?? 0;

    const deadlineStart = acceptanceDates[0];
    const deadlineEnd = acceptanceDates[acceptanceDates.length - 1];
    deadlineEnd.setHours(0, 0, 0, 0);

    if (deadlineStart.toDateString() === deadlineEnd.toDateString()) {
      deadlineEnd.setHours(22, 0, 0, 0);
    }

    activeDeadline.deadlineStart = deadlineStart.toISOString();
    activeDeadline.deadlineEnd = deadlineEnd.toISOString();
    activeDeadline.terminalId = terminalId;
    activeDeadline.trains = [
      {
        trainDate: departureDate,
        trainNumber: trainNumber,
      },
    ];

    await this.acceptanceRestrictionService.createAcceptanceDeadlineForTerminal(
      userId,
      terminalId,
      activeDeadline,
    );
  }

  async addOneToOneRestriction(inputData: {
    restrictionDate: string;
    startHour: number;
    endHour: number;
  }): Promise<void> {
    const userId = this.authStore.authUser?.userId ?? "";
    const terminalId = this.terminalStore.getTerminal()?.id ?? 0;
    const restriction: OneToOneRestriction = {
      restrictionStart: "",
      restrictionEnd: "",
      terminalId: terminalId,
    };

    restriction.restrictionStart = this.dateService.mergeDateWithHourUTC(
      inputData.restrictionDate,
      inputData.startHour,
    );

    restriction.restrictionEnd = this.dateService.mergeDateWithHourUTC(
      inputData.restrictionDate,
      inputData.endHour,
    );

    await this.acceptanceRestrictionService.createOneToOneRestrictionForTerminal(
      userId,
      terminalId,
      restriction,
    );
  }

  async showSnackbarError(errorMessage: string): Promise<void> {
    await this.snackbarStore.showSnackbar({
      text: t(errorMessage) as string,
      snackbarType: SnackbarType.ERROR,
    });
  }

  //TODO: check on sending locale instead of using vuei18n
  getFakeDeadlineDates(acceptanceDates: Date[]): string {
    const orderedAcceptanceDates =
      this.getOrderedAcceptanceDates(acceptanceDates);
    return `${[
      orderedAcceptanceDates[0],
      orderedAcceptanceDates[orderedAcceptanceDates.length - 1],
    ]
      .filter(e => e)

      .map(e =>
        this.dateService.parseReadableDateFormat(
          e.toISOString(),
          this.vueI18n.locale.value,
        ),
      )
      .join(" - ")}`;
  }

  getFakeDate(date: Date): string {
    if (!date) return date;
    return this.dateService.parseReadableDateFormat(
      date.toISOString(),
      this.vueI18n.locale.value,
    );
  }

  async deleteAcceptanceDeadline(
    acceptanceDeadline: AcceptanceDeadline,
  ): Promise<void> {
    const userId = this.authStore.authUser?.userId ?? "";
    const terminalId = this.terminalStore.getTerminal()?.id ?? 0;

    if (!userId || !terminalId) {
      throw new Error("User ID or Terminal ID is not defined.");
    }
    if (!acceptanceDeadline || !acceptanceDeadline.id) {
      throw new Error("Acceptance deadline ID is not defined.");
    }
    await this.acceptanceRestrictionService.deleteAcceptanceDeadlineForTerminal(
      userId,
      terminalId,
      acceptanceDeadline.id,
    );
  }

  async deleteOneToOneRestriction(
    oneToOneRestriction: OneToOneRestriction,
  ): Promise<void> {
    const userId = this.authStore.authUser?.userId ?? "";
    const terminalId = this.terminalStore.getTerminal()?.id ?? 0;
    if (!userId || !terminalId) {
      throw new Error("User ID or Terminal ID is not defined.");
    }
    if (!oneToOneRestriction || !oneToOneRestriction.id) {
      throw new Error("One-to-one restriction ID is not defined.");
    }
    await this.acceptanceRestrictionService.deleteOneToOneRestrictionForTerminal(
      userId,
      terminalId,
      oneToOneRestriction.id,
    );
  }

  parseOneToOneRestrictionRange(
    restriction: OneToOneRestriction,
    locale: string,
  ): string {
    const restrictionStart = restriction.restrictionStart;
    const restrictionEnd = restriction.restrictionEnd;
    const parsedFirstDate = this.dateService.parseReadableDateTimeFormat(
      restrictionStart,
      locale,
    );
    const timeOfLastRestriction = this.dateService.getHour(restrictionEnd);
    return `${parsedFirstDate}-${timeOfLastRestriction}`;
  }

  parseDepartureDateForElement(acceptanceDeadline: AcceptanceDeadline): string {
    if (!acceptanceDeadline?.trains || acceptanceDeadline.trains.length == 0)
      return "";
    const trainDate = acceptanceDeadline.trains[0].trainDate;
    if (trainDate == null) {
      throw new Error("Train date is not defined.");
    }
    return this.dateService.parseReadableDateFormat(
      trainDate,
      this.vueI18n.locale.value,
    );
  }

  parseAcceptanceDeadlineDateForElement(
    acceptanceDeadline: AcceptanceDeadline,
  ): string {
    if (acceptanceDeadline?.deadlineStart && acceptanceDeadline.deadlineEnd) {
      return [acceptanceDeadline.deadlineStart, acceptanceDeadline.deadlineEnd]
        .map(e =>
          this.dateService.parseReadableDateFormat(
            e,
            this.vueI18n.locale.value,
          ),
        )
        .join(" - ");
    }
    return "";
  }
}

let viewModel: AcceptanceRestrictionLogic | null = null;

export const getViewModel = (): AcceptanceRestrictionLogic => {
  if (!viewModel) {
    viewModel = new AcceptanceRestrictionLogic(
      acceptanceRestrictionsClient,
      dateService,
      i18n.global as Composer,
    );
  }
  return viewModel;
};
