<template>
  <v-select
    class="select"
    density="default"
    :items="trainOperatorsNames"
    :label="$t('selectTrainOperator')"
    :no-data-text="$t('no_data_available')"
    v-model="trainOperatorCurrentName"
    hide-details
    @update:modelValue="handleModelValueUpdate"
    variant="outlined"
    :return-object="true"
  />
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
import { TrainOperator } from "@/services/client/generated";
import DropdownItem from "@/internal-models/dropdown-item";
import { useTrainStore } from "@/store/useTrainStore";

const props = defineProps({
  trainOperators: {
    type: Array as PropType<TrainOperator[]>,
    required: true,
  },
});

const emit = defineEmits(["change-train-operator"]);

const trainStore = useTrainStore();

const handleModelValueUpdate = (item: DropdownItem): void => {
  trainOperatorCurrentName.value = {
    title: String(item.title),
    value: item.value as TrainOperator,
  };
};

const trainOperatorsNames = computed<DropdownItem[]>(() => {
  return props.trainOperators.map(e => ({
    title: e.name,
    value: e,
  }));
});

const trainOperatorCurrentName = computed({
  get: () => {
    return trainStore.selectedTrainOperator
      ? {
          title: trainStore.selectedTrainOperator.name,
          value: trainStore.selectedTrainOperator,
        }
      : null;
  },
  set: (item: DropdownItem | null) => {
    if (item) {
      trainStore.setSelectedTrainOperator(item.value as TrainOperator);
      emit("change-train-operator", item.value as TrainOperator);
    }
  },
});
</script>

<style scoped lang="scss">
.select {
  width: 100%;
}
</style>
