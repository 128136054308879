<template>
  <v-card
    class="card rounded-lg"
    color="#3f5d85"
    width="100%"
    elevation="2"
    rounded
  >
    <v-card-title class="card-title ma-0 pa-2">
      <v-row class="d-flex justify-space-between text-white">
        <v-col
          v-for="(field, i) in fields"
          :key="i"
          :cols="2"
          :lang="$i18n.locale"
          class="attribute-title text-wrap"
        >
          {{ fieldName(field) }}
        </v-col>
        <v-col cols="1">
          <slot name="action" />
        </v-col>
      </v-row>
    </v-card-title>
  </v-card>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

defineProps({
  fields: {
    type: Array as () => readonly string[],
    required: true,
  },
  cols: {
    type: Number,
    required: false,
    default: null,
  },
});

const { t } = useI18n();

const fieldName = (field: string): string => {
  if (!field) return "";
  return t(field) as string;
};
</script>

<style lang="scss">
.attribute-title {
  color: white;
  word-break: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;

  @media only screen and (min-width: 400px) {
    font-size: 0.9rem;
  }
  @media only screen and (min-width: 600px) {
    font-size: 1rem;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 1rem;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 1.2rem;
  }
}

.text-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
</style>
