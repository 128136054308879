<template>
  <v-list-item
    :data-testid="`test-${item.title}`"
    class="navigation-item mb-2"
    nav
    :class="{ active: isActiveRoute(item.pageName) }"
  >
    <v-icon :icon="getIcon(item)" :height="24" :width="24"></v-icon>
  </v-list-item>
</template>

<script setup lang="ts">
import { SidebarItem } from "@/internal-models/sidebar-item";
import { useRoute } from "vue-router";

defineProps<{
  item: SidebarItem;
}>();

const route = useRoute();

const isActiveRoute = (pageName: string) => {
  return route.path.startsWith(pageName);
};

const getIcon = (item: SidebarItem) => {
  return isActiveRoute(item.pageName)
    ? `custom:${item.activeIcon}`
    : `custom:${item.icon}`;
};
</script>

<style scoped lang="scss">
.navigation-item {
  font-size: 14px;
  font-weight: bold;
  background-color: rgb(var(--v-theme-greyish));

  &.active {
    background-color: rgb(var(--v-theme-primary));
  }
}
</style>
