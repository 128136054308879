<template>
  <v-container class="pa-0 ma-0">
    <v-col>
      <VirtualTable
        v-if="oneToOneRestrictions"
        :items="oneToOneRestrictions"
        :item-key="'id'"
        :headers="headers"
        :loading="loading"
        :header-section-name="$t('create_1_1_acceptance').toUpperCase()"
        :searchable="true"
        :search-text="$t('terminal_operator.search_by_start_date')"
        :search-type="'date'"
        :custom-filter="filterByDate"
        @load-more="allLoaded"
      >
        <template #header>
          <AddOneToOneRestriction @added-restriction="addRestriction" />
        </template>
        <template #item.period_of_restriction="{ value }">
          {{
            viewModel.parseOneToOneRestrictionRange(value, i18n.locale.value)
          }}
        </template>
        <template #item.actions="{ value }">
          <v-btn icon variant="flat" slim @click="openDeleteDialog(value)">
            <v-icon icon="mdi-close-circle-outline" color="red"></v-icon>
          </v-btn>
        </template>
      </VirtualTable>
    </v-col>

    <ConfirmationDialog
      :dialog="deleteDialog"
      :question-text="$t('deleteConfirmationAcceptanceDeadline')"
      @confirm="selectedRestriction && deleteRestriction(selectedRestriction)"
      @deny="closeDeleteDialog"
    />
  </v-container>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, computed } from "vue";
import AddOneToOneRestriction from "@/views/terminal-operator/one-to-one-restriction/AddOneToOneRestriction.vue";
import { OneToOneRestriction } from "@/services/client/generated";
import { getErrorByTypeOrDefault } from "@/utils/error-utils";
import { getViewModel } from "../acceptance-deadline/acceptance-restriction-logic";
import { useTerminalStore } from "@/store/useTerminalStore";
import { useForwarderStore } from "@/store/useForwarderStore";
import VirtualTable from "@/components/virtual-table/VirtualTable.vue";
import { useI18n } from "vue-i18n";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";

const viewModel = getViewModel();
const terminalStore = useTerminalStore();
const forwarderStore = useForwarderStore();
const i18n = useI18n();

const deleteDialog = ref(false);
const oneToOneRestrictions = ref<OneToOneRestriction[]>([]);
const selectedRestriction = ref<OneToOneRestriction | null>(null);
const loading = ref(true);

const fetchRestrictions = async (): Promise<void> => {
  loading.value = true;
  try {
    oneToOneRestrictions.value = await viewModel.getOneToOneRestrictions();
  } finally {
    loading.value = false;
  }
};

const addRestriction = async (inputData: {
  restrictionDate: string;
  startHour: number;
  endHour: number;
}): Promise<void> => {
  try {
    await viewModel.addOneToOneRestriction(inputData);
    await fetchRestrictions();
  } catch (e) {
    const errorMessage = i18n.t(getErrorByTypeOrDefault(e));
    await viewModel.showSnackbarError(errorMessage as string);
  }
};

const deleteRestriction = async (
  restriction: OneToOneRestriction,
): Promise<void> => {
  try {
    await viewModel.deleteOneToOneRestriction(restriction);
    await fetchRestrictions();
    closeDeleteDialog();
  } catch (e) {
    const errorMessage = i18n.t(getErrorByTypeOrDefault(e));
    await viewModel.showSnackbarError(errorMessage as string);
  }
};

const openDeleteDialog = (item: OneToOneRestriction): void => {
  deleteDialog.value = true;
  selectedRestriction.value = item;
};

const closeDeleteDialog = (): void => {
  deleteDialog.value = false;
  selectedRestriction.value = null;
};

const headers = computed(
  () =>
    [
      {
        title: i18n.t("periodOfRestriction"),
        value: "period_of_restriction",
        key: "period_of_restriction",
        align: "center",
        sortRaw: (a: OneToOneRestriction, b: OneToOneRestriction) => {
          const startA = new Date(a.restrictionStart).getTime();
          const startB = new Date(b.restrictionStart).getTime();
          return startA - startB;
        },
      },
      { title: "", value: "actions", align: "center" },
    ] as const,
);

const filterByDate = (
  _value: string,
  query: string,
  item?: { value: unknown; raw: OneToOneRestriction },
): boolean => {
  if (!item || !item.raw.restrictionStart) return false;

  const itemDate = new Date(item.raw.restrictionStart).toLocaleDateString();
  const selectedDate = new Date(query).toLocaleDateString();
  return itemDate === selectedDate;
};

const allLoaded = async (
  done: (status: "loading" | "error" | "empty" | "ok") => void,
) => {
  try {
    if (oneToOneRestrictions.value) {
      done("empty");
    } else {
      done("ok");
    }
  } catch (_) {
    done("error");
  }
};

onMounted(async () => {
  await fetchRestrictions();
});

watch(
  () => [terminalStore.terminal, forwarderStore.forwarder],
  async ([newTerminal, newForwarder], [oldTerminal, oldForwarder]) => {
    if (
      newTerminal?.id !== oldTerminal?.id ||
      newForwarder?.id !== oldForwarder?.id
    ) {
      await fetchRestrictions();
    }
  },
);
</script>

<style lang="scss" scoped>
@import "../../../scss/list-transitions";
</style>
