<template>
  <v-container class="pa-10" fluid>
    <v-row class="pl-10 pr-10" justify="space-between" no-gutters>
      <v-col xs="12" sm="12" md="5" align-self="center">
        <TheMainHeader
          class="text-left pl-10 pt-10 main-header"
          :text="$t('statusRequest')"
        />
      </v-col>

      <v-col xs="12" sm="12" md="5" align-self="end">
        <GetStatusStateIndicator
          :is-resubmit-batch-button-disabled="!fetchCompleted"
          @submit-csv="getBatchStatusQueue"
          class="pr-5"
          :length-of-all-requests="getStatusRequestQueue.length"
          :progress-state="progressState"
          :terminal="selectedTerminal"
        />
      </v-col>
    </v-row>
    <div class="pa-10">
      <AttributeRow class="pa-3 sticky-header" :fields="LIST_FIELDS">
        <template #action>
          <v-icon
            class="pa-2"
            @click="downloadCSV"
            size="large"
            :color="getColorForDownload"
          >
            mdi-download
          </v-icon>
        </template>
      </AttributeRow>
      <transition-group tag="div" name="list">
        <GetStatusResponseRow
          class="mt-4"
          v-for="(containerResponse, i) in getStatusResponseQueue"
          :key="i"
          :get-status-request="getStatusRequestQueue[i]"
          :get-status-response="containerResponse"
        />
      </transition-group>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import TheMainHeader from "@/components/TheMainHeader.vue";
import AttributeRow from "@/components/AttributeRow.vue";
import {
  GetStatusRequest,
  GetStatusRequestResponseTO,
  GetStatusResponse,
  Terminal,
} from "@/services/client/generated";
import GetStatusResponseRow from "@/views/forwarder-operator/get-status-batch/GetStatusResponseRow.vue";
import QueueProgressIndicator from "@/views/forwarder-operator/get-status-batch/models/queue-progress-indicator";
import GetStatusStateIndicator from "@/views/forwarder-operator/get-status-batch/GetStatusStateIndicator.vue";
import { getErrorByTypeOrDefault } from "@/utils/error-utils";
import { Result } from "@/internal-models/result";
import { validateContainerNumberFormat } from "@/validators/container-validators";
import { useI18n } from "vue-i18n";
import { getViewModel } from "./get-status-batch-logic";

const { t } = useI18n();

const viewModel = getViewModel();
const fetchCompleted = ref(false);
const terminalId = ref<number | null>(null);
const progressState = ref<QueueProgressIndicator>({
  currentIndex: 0,
  successfulItems: 0,
  failedItems: 0,
});
const getStatusRequestQueue = ref<GetStatusRequest[]>([]);
const getStatusResponseQueue = ref<Result<GetStatusResponse>[]>([]);
const LIST_FIELDS = [
  "type",
  "referenceNumber",
  "containerNumber",
  "containerType",
  "netWeight",
  "dangerousGoods",
  "tollData",
  "slotStart",
  "slotEnd",
];

const TIMEOUT = 300 as const;
const selectedFileName = ref("");
const selectedTerminalIdInput = ref("");
const selectedGetStatusRequestStringified = ref("");
const selectedTerminal = ref<Terminal | undefined>(undefined);

const router = useRouter();
const route = useRoute();

onMounted(async () => {
  if (!route.params.terminalIdInput) {
    router.back();
  }
  selectedFileName.value = route.params.fileName as string;
  selectedTerminalIdInput.value = route.params.terminalIdInput as string;
  selectedGetStatusRequestStringified.value = route.params
    .getStatusRequestStringified as string;
  await updateComponent();
  if (terminalId.value) {
    selectedTerminal.value = await viewModel.getTerminalById(terminalId.value);
  }
});

const updateComponent = async (): Promise<void> => {
  fetchCompleted.value = false;
  fetchDataFromRouteParams();
  progressState.value = {
    currentIndex: 0,
    successfulItems: 0,
    failedItems: 0,
  };
  getStatusResponseQueue.value = [];

  await fetchContainers(0);
};

const fetchContainers = async (index: number): Promise<void> => {
  if (index >= getStatusRequestQueue.value.length) {
    fetchCompleted.value = true;
    return;
  }
  const containerData = await getContainerData(index);
  resolveContainerData(containerData);
  index++;
  setTimeout(() => fetchContainers(index), TIMEOUT);
};

const fetchDataFromRouteParams = (): void => {
  getStatusRequestQueue.value = JSON.parse(
    selectedGetStatusRequestStringified.value,
  ) as GetStatusRequest[];
  terminalId.value = parseInt(selectedTerminalIdInput.value);
};

const getContainerData = async (
  index: number,
): Promise<Result<GetStatusResponse>> => {
  try {
    const containerData = getStatusRequestQueue.value[index];
    if (
      containerData.containerNumber &&
      !validateContainerNumberFormat(containerData.containerNumber)
    ) {
      return {
        error: {
          errorMessage: "container_number_invalid_format",
        },
        isOk: false,
      };
    }

    if (terminalId.value === null) {
      return {
        error: {
          errorMessage: "terminal_id_is_null",
        },
        isOk: false,
      };
    }
    const statusResponse = await viewModel.getStatus(
      terminalId.value,
      containerData,
    );

    if (!statusResponse.timeSlots || statusResponse.timeSlots.length === 0) {
      return {
        isOk: false,
        error: {
          errorMessage: "booking_has_no_timeslots",
        },
      };
    }

    return {
      value: statusResponse,
      isOk: true,
    };
  } catch (e) {
    const errorMessage = t(getErrorByTypeOrDefault(e));
    return {
      isOk: false,
      error: {
        errorMessage: errorMessage as string,
      },
    };
  }
};

const resolveContainerData = (result: Result<GetStatusResponse>): void => {
  getStatusResponseQueue.value.push(result);
  if (result.isOk) {
    progressState.value.successfulItems++;
  } else {
    progressState.value.failedItems++;
  }
  progressState.value.currentIndex++;
};

const downloadCSV = async (): Promise<void> => {
  if (!fetchCompleted.value) return;
  const getStatusBatched: GetStatusRequestResponseTO[] = [];
  for (let i = 0; i < getStatusRequestQueue.value.length; i++) {
    const request = getStatusRequestQueue.value[i];
    const response = getStatusResponseQueue.value[i];
    const getStatusGroupedResponse: GetStatusRequestResponseTO = {
      getStatusRequest: request,
    };
    if (response.isOk)
      getStatusGroupedResponse.getStatusResponse = response.value;
    else getStatusGroupedResponse.errorCode = response.error?.errorMessage;
    getStatusBatched.push(getStatusGroupedResponse);
  }
  if (terminalId.value) {
    await viewModel.downloadCSV(
      terminalId.value,
      getStatusBatched,
      selectedFileName.value,
    );
  }
};

const getBatchStatusQueue = async (payload: { file: File }): Promise<void> => {
  try {
    const parsingResponse = await viewModel.getStatusFileCallParameters(
      payload.file,
    );
    if (parsingResponse.failed) {
      await viewModel.showGetStatusError(parsingResponse.error);
      return;
    }
    selectedGetStatusRequestStringified.value = JSON.stringify(
      parsingResponse.items,
    );
    selectedFileName.value = payload.file.name;
    fetchDataFromRouteParams();
    await updateComponent();
  } catch (e) {
    const errorMessage = t(getErrorByTypeOrDefault(e));
    await viewModel.showGetStatusError(t(errorMessage) as string);
  }
};

const getColorForDownload = computed((): string => {
  return fetchCompleted.value ? "green" : "gray";
});
</script>

<style scoped lang="scss">
@import "../../../scss/list-transitions";

.sticky-header {
  position: sticky;
  top: 64px;
  z-index: 3;
}

.main-header {
  font-size: 2.5rem;
}
</style>
