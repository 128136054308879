//TODO: should be part of auth service

import { WebappUserRolesEnum } from "@/services/client/generated";

const conrooRoles: WebappUserRolesEnum[] = [
  "conroo_admin",
  "conroo_read",
  "conroo_write",
];

const hasCurrentRole = (
  role: WebappUserRolesEnum,
  currentUserRoles: WebappUserRolesEnum[],
) => {
  return currentUserRoles.includes(role);
};

export function hasPermissionBasedOnRole(
  requiredRoles: WebappUserRolesEnum[],
  currentRoles: WebappUserRolesEnum[],
): boolean {
  if (!requiredRoles || requiredRoles.length === 0) return true;
  if (!currentRoles || currentRoles.length === 0) return false;
  for (const userRole of currentRoles) {
    for (const requiredRole of requiredRoles) {
      if (userRole === requiredRole) {
        return true;
      }
    }
  }
  return false;
}

export const hasWritePermissions = (currentUserRoles: WebappUserRolesEnum[]) =>
  hasCurrentRole("conroo_write", currentUserRoles);

export const isAdmin = (currentUserRoles: WebappUserRolesEnum[]) =>
  hasCurrentRole("conroo_admin", currentUserRoles);

export const isConrooUser = (currentUserRoles: WebappUserRolesEnum[]) =>
  conrooRoles.some(e => currentUserRoles.includes(e));
