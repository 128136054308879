<template>
  <v-snackbar
    v-if="snackbarState.value"
    absolute
    location="bottom center"
    :timeout="-1"
    width="100%"
    v-model="snackbarState.value"
    :color="snackbarState.color"
  >
    <v-row
      align="center"
      no-gutters
      class="text-center snackbar-text rounded-bs"
    >
      <v-col cols="2" align-self="center">
        <v-icon class="pa-0 ma-0" size="xx-large">
          custom:{{ snackbarState.icon }}
        </v-icon>
      </v-col>
      <v-col cols="10" align-self="center">
        {{ snackbarState.text }}
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useSnackbarStore } from "@/store/useSnackbarStore";

const snackbarStore = useSnackbarStore();

const snackbarState = computed(() => snackbarStore.snackbarData);
</script>

<style scoped>
.snackbar-text {
  font-size: 1.3rem;
}
</style>
