<template>
  <div>
    <div v-if="loading">
      <LoadingIndicator></LoadingIndicator>
    </div>
    <div v-else>
      <v-card
        class="pt-10"
        width="100%"
        min-height="140"
        :color="statisticValue.color"
      >
        <v-card-title
          :class="`main-value justify-center align-center ${
            fontSize === 'small' ? 'text-h5' : ''
          }`"
          >{{ parsedStatisticValue }}</v-card-title
        >
      </v-card>

      <v-card
        min-height="100"
        max-height="100"
        class="pa-2 justify-center title-value"
        color="greyish"
      >
        {{ $t(statisticValue.title) }}
      </v-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import StatisticValue from "@/internal-models/statistic-value";
import { getViewModel } from "@/views/terminal-operator/dashboard/terminal-dashboard-logic";

type FontSize = "default" | "small";
const viewModel = getViewModel();
const props = defineProps<{
  statisticValue: StatisticValue;
  fontSize?: FontSize;
}>();

const normalizeValueIfTime = (value: number, unit: string): string => {
  if (unit === "min") {
    return viewModel.getMinutesForStatistic(value);
  }
  return value.toString();
};

const loading = computed((): boolean => {
  return !props.statisticValue;
});

const parsedStatisticValue = computed((): string => {
  if (!props.statisticValue) return "";
  return `${normalizeValueIfTime(
    props.statisticValue.value,
    props.statisticValue.unit,
  )}
  ${props.statisticValue.unit === "count" ? "" : props.statisticValue.unit}`;
});
</script>

<style lang="scss" scoped>
.main-value {
  color: white;
  font-weight: bold;
  font-size: 2.4rem;
}

.title-value {
  color: #00295b;
  font-weight: bold;
  font-size: 1rem;
}
</style>
