import { dateService, DateService } from "@/services/business/date-service";
import { useAuthStore } from "@/store/useAuthStore";
import { useForwarderStore } from "@/store/useForwarderStore";
import {
  BookingForForwarderTO,
  BookingsFilterRequestTO,
  ForwarderBookingsApi,
  TimeSlot,
  TruckerForForwarderTO,
} from "@/services/client/generated";
import { downloadBlob } from "@/utils/http-utils";
import { getAllValuesForSameKeyInCollection } from "@/utils/collection-utils";
import { forwarderBookingsClient } from "@/services/client/configs/services";

export class ForwarderBookingsOverviewLogic {
  constructor(
    private authStore: ReturnType<typeof useAuthStore>,
    private forwarderStore: ReturnType<typeof useForwarderStore>,
    private dateService: DateService,
    private forwarderOperatorBookingsService: ForwarderBookingsApi,
  ) {}

  async getBookingsForForwarder(
    filters: BookingsFilterRequestTO,
    page: number,
    size: number,
  ): Promise<BookingForForwarderTO[]> {
    const userId: string | undefined = this.authStore.authUser?.userId;
    const forwarder = this.forwarderStore.getForwarder();

    if (!forwarder || !userId) {
      throw new Error("Forwarder or User ID not found");
    }

    const response =
      await this.forwarderOperatorBookingsService.listBookingsForForwarder(
        userId,
        forwarder.id,
        filters,
        page,
        size,
      );
    return response.data;
  }

  async deleteBooking(bookingId: number, truckerId: string): Promise<void> {
    const userId: string | undefined = this.authStore.authUser?.userId;
    const forwarderId: number | undefined =
      this.forwarderStore.getForwarder()?.id;

    if (!userId || !forwarderId) {
      throw new Error("User ID or Forwarder ID not found");
    }

    // TODO: remove truckerId from interface on backend
    await this.forwarderOperatorBookingsService.cancelBooking(
      userId,
      forwarderId,
      truckerId,
      bookingId,
    );
  }

  async downloadTranshipment(
    bookingId: number,
    truckerId: string,
    name: string,
  ): Promise<void> {
    const userId: string | undefined = this.authStore.authUser?.userId;
    const forwarderId: number | undefined =
      this.forwarderStore.getForwarder()?.id;

    if (!userId || !forwarderId) {
      throw new Error("User ID or Forwarder ID not found");
    }

    const response =
      await this.forwarderOperatorBookingsService.getTranshipmentConfirmationDocument(
        userId,
        forwarderId,
        truckerId,
        bookingId,
        {
          responseType: "blob",
        },
      );

    // TODO: response should be string and not string[] from the api generation
    downloadBlob(
      response.data as unknown as string,
      name + ".pdf",
      "application/pdf",
    );
  }

  parseTimeslot(timeslot: TimeSlot, locale: string): string {
    return `${this.dateService.parseReadableDateTimeFormat(
      timeslot.slotBegin,
      locale,
    )}-${this.dateService.getHour(timeslot.slotEnd)}`;
  }

  mapBookingValuesIntoUIComponent(
    bookings: BookingForForwarderTO[],
  ): Record<string, object[]> {
    const result: Record<string, object[]> = {};
    const groupedValues = getAllValuesForSameKeyInCollection(
      bookings as Record<string, object>[],
    );

    result["status"] = groupedValues["status"] ?? [].sort();
    result["bookingType"] = groupedValues["type"] ?? [].sort();
    result["containerType"] = groupedValues["containerType"] ?? [].sort();
    result["truckerName"] =
      groupedValues["truckerInformation"] ??
      [].sort((a: TruckerForForwarderTO, b: TruckerForForwarderTO) => {
        const firstTruckerName = `${a?.firstName} ${a?.lastName}`.trim();
        const secondTruckerName = `${b?.firstName} ${b?.lastName}`.trim();
        return firstTruckerName.localeCompare(secondTruckerName);
      });

    result["referenceNumber"] = groupedValues["referenceNumber"] ?? [].sort();
    result["containerNumber"] = groupedValues["containerNumber"] ?? [].sort();
    result["terminal"] = groupedValues["terminalName"] ?? [].sort();
    result["licensePlate"] = groupedValues["licensePlate"] ?? [].sort();
    result["timeSlotId"] =
      groupedValues["timeslot"] ??
      [].sort((a, b) => {
        const [firstTimeSlot, secondTimeSlot] = [a, b] as TimeSlot[];
        if (
          this.dateService.isBeforeDate(
            new Date(firstTimeSlot.slotBegin),
            new Date(secondTimeSlot.slotBegin),
          )
        )
          return -1;
        if (
          this.dateService.isEqual(
            new Date(firstTimeSlot.slotBegin),
            new Date(secondTimeSlot.slotBegin),
          )
        )
          return 0;
        return 1;
      });

    return result;
  }
}

let viewModel: ForwarderBookingsOverviewLogic | null = null;

export const getViewModel = (): ForwarderBookingsOverviewLogic => {
  if (!viewModel) {
    viewModel = new ForwarderBookingsOverviewLogic(
      useAuthStore(),
      useForwarderStore(),
      dateService,
      forwarderBookingsClient,
    );
  }

  return viewModel;
};
