<template>
  <v-card color="greyish">
    <v-progress-circular
      class="ma-5"
      indeterminate
      :size="180"
      color="primary"
    />
  </v-card>
</template>

<script setup lang="ts"></script>
