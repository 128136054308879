<template>
  <div>
    <div v-if="!readonly" class="text-truncate-container">
      <span class="text-truncate-content">
        {{ value }}
      </span>
      <v-tooltip
        v-if="value.length > truncateLength"
        activator="parent"
        location="bottom"
      >
        <span>{{ value }}</span>
      </v-tooltip>
    </div>
    <div v-else>
      <v-text-field
        class="centered-input"
        density="compact"
        variant="outlined"
        hide-details
        :label="placeholder"
        :bg-color="color"
        :model-value="value"
        @update:modelValue="(v: string) => $emit('input-change', v)"
        :rules="rules"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";

type ValidationRule = (value: string) => boolean | string;

const truncateLength = 10;

defineProps({
  value: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    required: true,
  },
  readonly: {
    type: Boolean,
    required: false,
    default: false,
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    required: false,
    default: () => [],
  },
  placeholder: {
    type: String,
    required: false,
    default: null,
  },
});

defineEmits(["input-change"]);
</script>

<style lang="scss">
.centered-input input {
  text-align: center;
  color: #3f5d85 !important;

  @media only screen and (min-width: 400px) {
    font-size: 0.9rem;
  }
  @media only screen and (min-width: 600px) {
    font-size: 1rem;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 1rem;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 1.2rem;
  }
}

.text-truncate-container {
  max-width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-truncate-content {
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
