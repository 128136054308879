import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { Terminal } from "@/services/client/generated";
import { terimnalUserClient } from "@/services/client/configs/services";

export const useTerminalStore = defineStore(
  "terminalStore",
  () => {
    const terminal = ref<Terminal | null>(null);
    const terminals = ref<Terminal[] | null>(null);

    const terminalData = computed(() => terminal.value);
    const terminalsData = computed(() => terminals.value);

    const setTerminal = (newTerminal: Terminal | null) => {
      terminal.value = newTerminal;
    };

    const setTerminals = (newTerminals: Terminal[]) => {
      terminals.value = newTerminals;
    };

    const setTerminalData = (newTerminal: Terminal | null): Terminal | null => {
      if (newTerminal) {
        setTerminal(newTerminal);
      }
      return newTerminal;
    };

    const getTerminal = (): Terminal | null => {
      if (terminal.value) {
        return terminal.value;
      }

      const savedTerminal = localStorage.getItem("terminal");
      if (savedTerminal) {
        terminal.value = JSON.parse(savedTerminal);
        return terminal.value;
      }
      return null;
    };

    const getTerminals = async (
      userId: string,
    ): Promise<Terminal[] | undefined> => {
      const response = await terimnalUserClient.getTerminalsForUser(userId);
      setTerminals(response.data);
      return response.data;
    };

    return {
      terminal,
      terminals,
      terminalData,
      terminalsData,
      setTerminal,
      setTerminals,
      setTerminalData,
      getTerminal,
      getTerminals,
    };
  },
  {
    persist: true,
  },
);
