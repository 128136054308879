import { AxiosError } from "axios";
import { FirebaseError } from "firebase/app";

export type ErrorHandler = (error: unknown) => string;

export function getErrorByTypeOrDefault(
  error: unknown,
  defaultMessage?: string,
): string {
  if (error instanceof AxiosError && error.response?.data?.message) {
    return error.response.data.message;
  }

  if (error instanceof FirebaseError) {
    return `Firebase Error: ${error.message} (Code: ${error.code})`;
  }

  if (error instanceof Error) {
    if (error.message) return error.message;
  }

  return defaultMessage ?? "unknownError";
}
