import {
  IspsTruckerChangeTODocumentStatusEnum,
  IspsTruckerChangeTOTerrorStatusEnum,
} from "@/services/client/generated";

export const terrorStates: IspsTruckerChangeTOTerrorStatusEnum[] = [
  "OK",
  "WARNING",
  "RED_ALERT",
  "MANUALLY_APPROVED",
  "MANUALLY_DENIED",
];

export const idStates: IspsTruckerChangeTODocumentStatusEnum[] = [
  "ACCEPTED",
  "PENDING",
  "DENIED",
  "WARN",
  "DENIED_PRELIMINARY",
  "MANUALLY_APPROVED",
  "MANUALLY_DENIED",
  "FAILED",
];
