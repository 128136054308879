<template>
  <div v-if="props.isEditable">
    <TextInput v-model="model" :placeholder="props.placeholder" />
  </div>
  <div v-else>{{ props.title }}</div>
</template>

<script lang="ts" setup>
import TextInput from "@/components/user-management/TextInput.vue";
import { ref, toRefs, watch } from "vue";

const props = defineProps<{
  title: string | undefined;
  modelValue: string | undefined;
  isEditable?: boolean;
  placeholder: string;
}>();

const { modelValue } = toRefs(props);
const model = ref(modelValue.value ?? props.title);

const emit = defineEmits<{
  "update:modelValue": [value: string | undefined];
}>();

watch(model, newValue => {
  emit("update:modelValue", newValue);
});

watch(
  () => props.modelValue,
  newValue => {
    model.value = newValue;
  },
);
</script>
