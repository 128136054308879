import {
  ispsTerminalOperatorBlockList,
  webappTruckersClient,
} from "@/services/client/configs/services";
import {
  ListTruckersForFacilityBlockedStateEnum,
  TerminalOperatorTruckerTO,
  TruckerBlocklistRequestTO,
} from "@/services/client/generated";
import { useAuthStore } from "@/store/useAuthStore";
import { useTerminalStore } from "@/store/useTerminalStore";
import { ref } from "vue";

export function useTerminalOperatorBlockListLogic() {
  const authStore = useAuthStore();
  const terminalStore = useTerminalStore();

  const size = ref(25);
  const page = ref(0);
  const loading = ref(false);

  const truckers = ref<TerminalOperatorTruckerTO[]>([]);
  const selectedTrucker = ref<TerminalOperatorTruckerTO | null>(null);
  const reason = ref("");

  async function getBlockListForFacility(): Promise<
    TerminalOperatorTruckerTO[]
  > {
    try {
      const userId = authStore.authUser?.userId;
      const terminalId = terminalStore.terminal?.id;

      if (!userId || !terminalId) {
        truckers.value = [];
        return [];
      }

      const { data } = await webappTruckersClient.listTruckersForFacility(
        userId,
        terminalId,
        page.value,
        size.value,
        undefined,
        ListTruckersForFacilityBlockedStateEnum.Blocked,
      );

      if (data && data.length > 0) {
        const newTruckers = data.filter(
          newTrucker =>
            !truckers.value.some(
              existingTrucker => existingTrucker.id === newTrucker.id,
            ),
        );

        if (newTruckers.length > 0) {
          truckers.value = [...(truckers.value ?? []), ...newTruckers];
          page.value++;
        }
      } else if (data && data.length === 0 && page.value === 0) {
        truckers.value = data;
      }
      return data ?? [];
    } catch (_) {
      truckers.value = [];
      return [];
    }
  }

  async function fetchTruckersByLastName(lastName: string) {
    if (!authStore.authUser || !terminalStore.terminal) {
      truckers.value = [];
      return;
    }

    const { data } = await webappTruckersClient.listTruckersForFacility(
      authStore.authUser.userId,
      terminalStore.terminal.id,
      undefined,
      undefined,
      lastName,
      ListTruckersForFacilityBlockedStateEnum.NotBlocked,
    );

    truckers.value = data ?? [];
  }

  async function deleteBlockListEntry(id: string) {
    if (authStore.authUser && terminalStore.terminal) {
      await ispsTerminalOperatorBlockList.deleteBlockListEntryForIspsTerminalByTrucker(
        authStore.authUser.userId,
        terminalStore.terminal.id,
        id,
      );
      truckers.value = truckers.value.filter(trucker => trucker.id !== id);
      page.value = 0;
      size.value = 25;
      await getBlockListForFacility();
    }
  }

  async function addBlockListEntry(truckerRequest: TruckerBlocklistRequestTO) {
    if (authStore.authUser && terminalStore.terminal) {
      await ispsTerminalOperatorBlockList.createBlockListEntryForIspsTerminal(
        authStore.authUser.userId,
        terminalStore.terminal.id,
        truckerRequest,
      );
      page.value = 0;
      size.value = 25;
      await getBlockListForFacility();
    }
  }

  function resetForm() {
    selectedTrucker.value = null;
    truckers.value = [];
    reason.value = "";
  }

  const handleLoadMore = async (
    done: (status: "loading" | "error" | "empty" | "ok") => void,
  ) => {
    if (loading.value) return;

    loading.value = true;

    done("loading");

    try {
      const loadedBlockList = await getBlockListForFacility();

      if (loadedBlockList.length === 0) {
        done("empty");
      } else {
        done("ok");
      }
    } catch (_) {
      done("error");
    } finally {
      loading.value = false;
    }
  };

  return {
    truckers,
    selectedTrucker,
    reason,
    page,
    size,
    resetForm,
    fetchTruckersByLastName,
    getBlockListForFacility,
    deleteBlockListEntry,
    addBlockListEntry,
    handleLoadMore,
  };
}
