<template>
  <v-text-field
    type="text"
    v-model="internalModelValue"
    :placeholder="placeholder"
    :class="wide ? 'w-80' : ''"
    variant="outlined"
    color="success"
    density="compact"
    hide-details
  />
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
  modelValue: string | undefined;
  placeholder: string;
  wide?: boolean;
}>();

const emit = defineEmits(["update:modelValue"]);

const internalModelValue = computed({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
});
</script>
