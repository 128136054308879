import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { WebappUserRolesEnum } from "@/services/client/generated";
import { hasPermissionBasedOnRole } from "@/utils/role-utils";
import { useAuthStore } from "@/store/useAuthStore";
import { useI18n } from "vue-i18n";
import {
  SidebarItem,
  SidebarItemService,
} from "@/internal-models/sidebar-item";
import getSidebarItems from "@/constants/sidebar-items";

export default function useNavigationLogic() {
  const router = useRouter();
  const route = useRoute();
  const authStore = useAuthStore();
  const { locale } = useI18n();

  const drawer = ref(false);
  const selectedItem = ref<SidebarItem | null>(null);
  const groupedItems = ref(getSidebarItems());

  const handleItemClick = (item: SidebarItem) => {
    if (item.services) {
      if (selectedItem.value?.pageName === item.pageName) {
        drawer.value = !drawer.value;
      } else {
        selectedItem.value = item;
        drawer.value = true;
      }
    } else {
      router.push(item.pageName);
    }
  };

  const hasPermission = (pageRoles: WebappUserRolesEnum[]): boolean => {
    const userRoles: WebappUserRolesEnum[] = authStore.userData?.roles ?? [];
    return hasPermissionBasedOnRole(pageRoles, userRoles);
  };

  const logOut = async (): Promise<void> => {
    await authStore.logout();
    router.push("/login");
  };

  const goToSupport = (): void => {
    const supportUrl = `https://support.conroo.com/${locale.value === "en" ? "en" : "de"}/`;
    window.open(supportUrl, "_blank");
  };

  const isActiveRoute = (pageName: string) => {
    return route.path === pageName;
  };

  const getIcon = (item: SidebarItemService) => {
    return isActiveRoute(item.pagePath)
      ? `custom:${item.activeIcon}`
      : `custom:${item.icon}`;
  };

  return {
    drawer,
    selectedItem,
    groupedItems,
    handleItemClick,
    hasPermission,
    logOut,
    goToSupport,
    isActiveRoute,
    getIcon,
  };
}
