<template>
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1165_7320)">
      <path
        class="cls-1"
        d="M7.99992 7.26667C7.59325 7.26667 7.26659 7.59334 7.26659 8C7.26659 8.40667 7.59325 8.73334 7.99992 8.73334C8.40659 8.73334 8.73325 8.40667 8.73325 8C8.73325 7.59334 8.40659 7.26667 7.99992 7.26667ZM7.99992 1.33334C4.31992 1.33334 1.33325 4.32 1.33325 8C1.33325 11.68 4.31992 14.6667 7.99992 14.6667C11.6799 14.6667 14.6666 11.68 14.6666 8C14.6666 4.32 11.6799 1.33334 7.99992 1.33334ZM9.45992 9.46L3.99992 12L6.53992 6.54L11.9999 4L9.45992 9.46Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1165_7320">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.cls-1 {
  fill: #14005c;
}
</style>
