<template>
  <div v-if="!getStatusResponse.isOk">
    <v-card
      class="card rounded-lg"
      color="#FAA0A0"
      width="100%"
      elevation="2"
      rounded
    >
      <v-card-title class="not-found-card-title pa-5">
        <v-row justify="center" align="center">
          <v-col cols="12" class="text-no-wrap">
            {{ containerErrorMessage }}
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
  </div>
  <div v-else>
    <v-card
      class="card rounded-lg"
      color="#bfc9d7"
      width="100%"
      elevation="2"
      rounded
    >
      <v-card-title class="card-title pa-5">
        <v-row align-content="space-around">
          <v-col class="items" align-self="center">
            {{ missing($t(getStatusRequest.type)) }}
          </v-col>

          <v-col class="items" align-self="center">
            {{ missing(getStatusRequest.referenceNumber) }}
          </v-col>

          <v-col class="items" align-self="center">
            {{ missing(getStatusRequest.containerNumber) }}
          </v-col>

          <v-col class="items" align-self="center">
            {{ missing(getStatusResponse.value?.container?.containerType) }}
          </v-col>

          <v-col class="items" align-self="center">
            {{
              missing(getStatusResponse.value?.container?.netWeight?.toString())
            }}
          </v-col>

          <v-col class="items" align-self="center">
            {{ hasToll ? $t("yes") : $t("no") }}
          </v-col>

          <v-col class="items" align-self="center">
            {{ hasDangerousGoods ? $t("yes") : $t("no") }}
          </v-col>
          <!--first one -->
          <v-col class="items" align-self="center">
            {{ parseTimeslot(getStatusResponse.value?.timeSlots?.[0]) }}
          </v-col>
          <!--last one-->
          <v-col class="items" align-self="center">
            {{
              parseTimeslot(
                getStatusResponse.value?.timeSlots?.[
                  getStatusResponse.value.timeSlots.length - 1
                ],
              )
            }}
          </v-col>
          <v-col cols="1">
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import i18n from "@/plugins/i18n";
import {
  GetStatusRequest,
  GetStatusResponse,
  TimeSlot,
} from "@/services/client/generated";
import { Result } from "@/internal-models/result";
import { useI18n } from "vue-i18n";
import { getViewModel } from "../bookings-overview/forwarder-bookings-overview-logic";

const props = defineProps<{
  getStatusRequest: GetStatusRequest;
  getStatusResponse: Result<GetStatusResponse>;
}>();

const viewModel = getViewModel();

const { locale } = useI18n();

const hasDangerousGoods = computed(() => {
  const getStatusResponse = props.getStatusResponse?.value;
  return getStatusResponse?.container?.listDangerousGoodsData?.length !== 0;
});

const hasToll = computed(() => {
  const getStatusResponse = props.getStatusResponse?.value;
  return getStatusResponse?.container?.listTollData?.length !== 0;
});

const containerErrorMessage = computed(() => {
  const { containerNumber, referenceNumber, type } = props.getStatusRequest;
  const appropriateLine = `containerError${
    containerNumber ? "With" : "Without"
  }ContainerNumber`;

  return i18n.global.t(appropriateLine, {
    containerNumber,
    referenceNumber,
    error: i18n.global.t(props.getStatusResponse?.error?.errorMessage ?? ""),
    type: i18n.global.t(type ?? ""),
  }) as string;
});

const parseTimeslot = (timeslot: TimeSlot | undefined): string => {
  if (!timeslot) return "";
  return viewModel.parseTimeslot(timeslot, locale.value);
};

const missing = (value: string | undefined | null): string => {
  if (!value) return i18n.global.t("missing") as string;
  return value;
};
</script>

<style scoped lang="scss">
//TODO: easily can be generified
.not-found-card-title {
  color: #00295b;

  @media only screen and (min-width: 400px) {
    font-size: 0.9rem;
  }
  @media only screen and (min-width: 600px) {
    font-size: 1rem;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 1rem;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 1.2rem;
  }
}

.card-title {
  color: #00295b;

  @media only screen and (min-width: 400px) {
    font-size: 0.9rem;
  }
  @media only screen and (min-width: 600px) {
    font-size: 1rem;
  }
  @media only screen and (min-width: 1000px) {
    font-size: 1rem;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 1.2rem;
  }
}

.items {
  padding: 0;
  word-break: normal;
}
</style>
