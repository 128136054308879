import PAGE_NAMES from "@/internal-models/enums/page-names";
import { WebappUserRolesEnum } from "@/services/client/generated";

const getPageCards = () => {
  return {
    terminalOperator: {
      background: "terminals",
      title: "terminalOperator",
      visible: true,
      page: PAGE_NAMES.TERMINAL_OPERATOR,
      roles: [
        WebappUserRolesEnum.ConrooWrite,
        WebappUserRolesEnum.ConrooRead,
        WebappUserRolesEnum.ConrooAdmin,
        WebappUserRolesEnum.TerminalOperationsAdmin,
        WebappUserRolesEnum.TerminalOperationsRead,
        WebappUserRolesEnum.TerminalOperationsWrite,
      ],
    },
    trainOperator: {
      background: "operator-chair",
      title: "trainOperator",
      visible: true,
      page: PAGE_NAMES.TRAIN_OPERATOR,
      roles: [
        WebappUserRolesEnum.ConrooWrite,
        WebappUserRolesEnum.ConrooRead,
        WebappUserRolesEnum.ConrooAdmin,
        WebappUserRolesEnum.TrainOperatorRead,
        WebappUserRolesEnum.TrainOperatorWrite,
      ],
    },
    forwarderOperator: {
      background: "trucks",
      title: "forwarderOperator",
      visible: true,
      page: PAGE_NAMES.FORWARDER_OPERATOR,
      roles: [
        WebappUserRolesEnum.ConrooWrite,
        WebappUserRolesEnum.ConrooRead,
        WebappUserRolesEnum.ConrooAdmin,
        WebappUserRolesEnum.ForwarderRead,
        WebappUserRolesEnum.ForwarderWrite,
      ],
    },
    conrooUser: {
      background: "graph",
      title: "conrooUser",
      visible: true,
      page: PAGE_NAMES.USER_MANAGEMENT,
      roles: [
        WebappUserRolesEnum.ConrooWrite,
        WebappUserRolesEnum.ConrooRead,
        WebappUserRolesEnum.ConrooAdmin,
      ],
    },
  };
};

export default getPageCards;
