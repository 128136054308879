import Duration from "@/internal-models/enums/duration";
import TimeRange from "@/internal-models/time-range";

const dropdownRanges: Array<TimeRange> = [
  { duration: Duration.DAY, amount: 1 },
  { duration: Duration.WEEK, amount: 1 },
  { duration: Duration.MONTH, amount: 1 },
  { duration: Duration.YEAR, amount: 1 },
];

export default dropdownRanges;
