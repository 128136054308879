<template>
  <div>
    <TheMainHeader class="text-left pl-10 pt-10" :text="$t('statusRequest')" />
    <v-container class="pa-5 pt-10">
      <v-card
        class="card rounded-lg"
        color="#00295b"
        width="100%"
        elevation="2"
        rounded
      >
        <div class="ma-4">
          <v-row>
            <v-col sm="12" md="4">
              <TheDropdown
                data-testid="test-getStatusTerminalsDropdown"
                v-if="terminals"
                :default-value="terminal"
                :items="terminals"
                @change-item="changeTerminal"
                :item-title="(e: Terminal) => getItemTitle(e)"
                :item-value="(e: Terminal) => getItemValue(e)"
                label="terminal"
                :rules="[terminalMustBeSelected]"
                :outlined="true"
              />
            </v-col>
            <v-col sm="12" md="8">
              <v-radio-group
                class="radio-btn max-content"
                v-model="getStatusMode"
                inline
              >
                <v-radio color="#ffffffe6" value="single">
                  <template #label>
                    <div class="radio-btn">
                      {{ $t("singleStatusDescription") }}
                    </div>
                  </template>
                </v-radio>
                <v-radio color="#ffffffe6" value="batch">
                  <template #label>
                    <div class="radio-btn">
                      {{ $t("batchStatusDescription") }}
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="getStatusMode === 'single'">
            <GetStatusSingleRequestForm
              :preferences="getStatusPreferences"
              @change-data="savePreferences"
              :status-response="statusResponse"
              :terminals="terminals"
              :terminal="terminal"
              :terminal-id="terminalId"
              @submit-request="getStatus"
            />
          </v-row>
          <v-row v-if="getStatusMode === 'batch'">
            <GetStatusBatchRequestForm
              @change-terminal="changeTerminal"
              :status-response="statusResponse"
              :terminal-id="terminalId"
              @submit-csv="getBatchStatusQueue"
            />
          </v-row>
        </div>
      </v-card>

      <v-row
        v-if="!!statusResponse && getStatusMode === 'single'"
        class="pa-6 pt-7"
      >
        <transition name="fade">
          <v-row>
            <TableExplanationRow
              title="selectTimeSlots"
              :fields="['days', 'hours', 'oneToOneRestriction']"
            />
            <v-col sm="12" md="8">
              <TimeslotSelection
                :time-slots="statusResponse.timeSlots"
                @change-day="changeDay"
                @select-timeSlot="setTimeSlot"
              />
            </v-col>
            <v-col sm="12" md="4">
              <v-list class="overflow-y-auto restriction-col">
                <StaticTableEntry
                  v-for="value in showingRestrictions"
                  :restriction-time="value.time"
                  :key="value.id"
                  data-testid="test-getStatusRestrictions"
                />
              </v-list>
            </v-col>
          </v-row>
        </transition>
      </v-row>

      <v-row v-if="!!statusResponse && getStatusMode === 'single'">
        <SelectingTruckerRow
          @create-booking="createBooking"
          @change-trucker="changeTrucker"
          :truckers="truckers"
          class="ml-5 mr-5"
        />
      </v-row>

      <v-container
        v-if="oneToOneRestrictions.length != 0 && !statusResponse"
        class="pa-5 pt-10"
      >
        <TheMainHeader
          class="text-left pl-10 pt-10"
          :text="`${$t('acceptanceRestrictions')} ${terminalName}`"
        />
        <VirtualTable
          :items="oneToOneRestrictions"
          :key="oneToOneRestrictions.length"
          :headers="headers"
          :loading="loading"
          @load-more="allLoaded"
        >
          <template #item.type> 1:1 </template>
          <template #item.period_of_restriction="{ value }">
            {{
              viewModel.parseOneToOneRestrictionRange(value, i18n.locale.value)
            }}
          </template>
        </VirtualTable>
      </v-container>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch } from "vue";
import TheDropdown from "@/components/TheDropdown.vue";
import TheMainHeader from "@/components/TheMainHeader.vue";
import GetStatusSingleRequestForm from "@/views/forwarder-operator/get-status/GetStatusSingleRequestForm.vue";
import TimeslotSelection from "@/views/forwarder-operator/get-status/TimeslotSelection.vue";
import SelectingTruckerRow from "@/views/forwarder-operator/get-status/SelectingTruckerRow.vue";
import GetStatusBatchRequestForm from "@/views/forwarder-operator/get-status/GetStatusBatchRequestForm.vue";
import StaticTableEntry from "@/views/forwarder-operator/get-status/StaticTableEntry.vue";
import TableExplanationRow from "@/views/forwarder-operator/get-status/TableExplanationRow.vue";
import {
  GetStatusRequest,
  GetStatusResponse,
  OneToOneRestriction,
  Terminal,
  TimeSlot,
  TruckerForForwarderTO,
} from "@/services/client/generated";
import { GetStatusFormPreferences } from "@/store/interfaces/user-role-config";
import { getErrorByTypeOrDefault } from "@/utils/error-utils";
import { useI18n } from "vue-i18n";
import router from "@/router";
import { getViewModel } from "./get-status-logic";
import VirtualTable from "@/components/virtual-table/VirtualTable.vue";
import { useForwarderStore } from "@/store/useForwarderStore";

const i18n = useI18n();
const viewModel = getViewModel();
const forwarderStore = useForwarderStore();

const getStatusRequest = ref<GetStatusRequest | null>();
const terminalId = ref(-1);
const terminalName = ref("");
const terminals = ref<Terminal[]>([]);
const terminal = ref<Terminal | null>();
const statusResponse = ref<GetStatusResponse | null>();
const selectedTimeSlot = ref<TimeSlot | null>();
const truckers = ref<TruckerForForwarderTO[]>([]);
const selectedTruckerId = ref("");
const oneToOneRestrictions = ref<OneToOneRestriction[]>([]);
const getStatusPreferences = ref<GetStatusFormPreferences | undefined>();
const showingRestrictions = ref<{ id: number | undefined; time: string }[]>([]);
const getStatusMode = ref("single");
const loading = ref(true);

const allLoaded = async (
  done: (status: "loading" | "error" | "empty" | "ok") => void,
) => {
  try {
    if (oneToOneRestrictions.value) {
      done("empty");
    } else {
      done("ok");
    }
  } catch (_) {
    done("error");
  }
};

const getItemTitle = (e: unknown) => {
  return (e as Terminal).name;
};

const getItemValue = (e: unknown) => {
  return (e as Terminal).id.toString();
};

const restrictionsMap = computed(() => {
  return viewModel.parseOneToOneRestrictionMap(
    oneToOneRestrictions.value,
    i18n.locale.value,
  );
});

const terminalMustBeSelected = (): boolean | string => {
  if (!terminal.value) return i18n.t("notSelected") as string;
  return true;
};

const changeTerminal = (payload: { label: string; value: string }) => {
  if (payload.value === null) {
    terminal.value = null;
    terminalId.value = -1;
    terminalName.value = "";
    getStatusPreferences.value = {
      terminal: undefined,
      bookingType: getStatusPreferences.value?.bookingType,
    };
    savePreferences(getStatusPreferences.value);
  } else {
    const selectedTerminal = terminals.value.find(
      e => e.id === parseInt(payload.value),
    );
    if (!selectedTerminal) return;
    terminal.value = selectedTerminal;
    terminalId.value = selectedTerminal.id;
    terminalName.value = selectedTerminal.name;
    getStatusPreferences.value = {
      terminal: terminal.value,
      bookingType: getStatusPreferences.value?.bookingType,
    };
    savePreferences(getStatusPreferences.value);
    fetchRestrictions();
  }
};

const getStatus = async (payload: {
  getStatusRequest: GetStatusRequest;
  terminal: Terminal;
}) => {
  try {
    getStatusRequest.value = payload.getStatusRequest;
    if (
      payload.getStatusRequest.terminalId &&
      payload.getStatusRequest.terminalId != terminalId.value
    ) {
      terminalId.value = payload.getStatusRequest.terminalId;
      await fetchRestrictions();
    }
    terminal.value = payload.terminal;
    const statusResponseVal = await viewModel.getStatus(
      payload.getStatusRequest,
    );
    if (
      !statusResponseVal.timeSlots ||
      statusResponseVal.timeSlots.length === 0
    ) {
      await viewModel.showGetStatusError(
        i18n.t("booking_has_no_timeslots") as string,
      );
      return;
    }
    statusResponse.value = statusResponseVal;
    truckers.value = await viewModel.getTruckersForForwarder();
  } catch (e) {
    const errorMessage = i18n.t(getErrorByTypeOrDefault(e));
    await viewModel.showGetStatusError(i18n.t(errorMessage) as string);
  }
};

const changeDay = (payload: { day: string }) => {
  showingRestrictions.value = [];
  for (const [restrictionDay, restrictions] of Object.entries(
    restrictionsMap.value,
  )) {
    if (restrictionDay == payload.day) {
      showingRestrictions.value.push(...restrictions);
    }
  }
};

const getBatchStatusQueue = async (payload: {
  file: File;
  terminalId: number;
}) => {
  try {
    const parsingResponse = await viewModel.getStatusFileCallParameters(
      payload.file,
    );
    if (parsingResponse.failed) {
      await viewModel.showGetStatusError(parsingResponse.error);
      return;
    }
    const params = {
      fileName: payload.file.name,
      getStatusRequestStringified: JSON.stringify(parsingResponse.items),
      terminalIdInput: payload.terminalId.toString(),
    };
    router.push({ name: "batchStatusRequest", params });
  } catch (e) {
    const errorMessage = i18n.t(getErrorByTypeOrDefault(e));
    await viewModel.showGetStatusError(i18n.t(errorMessage) as string);
  }
};

const setTimeSlot = (timeSlot: TimeSlot) => {
  selectedTimeSlot.value = timeSlot;
};

const changeTrucker = (payload: { label: string; value: string }) => {
  selectedTruckerId.value = payload.value;
};

const createBooking = async () => {
  const trucker = truckers.value.filter(
    e => e.userId === selectedTruckerId.value,
  )[0];
  const createdText = i18n.t("bookingCreated") as string;
  if (
    getStatusRequest.value &&
    statusResponse.value &&
    selectedTimeSlot.value &&
    terminal.value
  ) {
    await viewModel.createBooking(
      createdText,
      getStatusRequest.value,
      statusResponse.value,
      trucker,
      selectedTimeSlot.value,
      terminal.value?.id,
    );
  }
  setTimeout(() => refreshState(), 500);
};

const refreshState = () => {
  getStatusRequest.value = null;
  statusResponse.value = null;
  selectedTimeSlot.value = null;
  truckers.value = [];
  selectedTruckerId.value = "";
};
const fetchRestrictions = async () => {
  loading.value = true;

  try {
    if (terminalId.value) {
      oneToOneRestrictions.value = await viewModel.getOneToOneRestrictions(
        terminalId.value,
      );
    } else {
      oneToOneRestrictions.value = [];
    }
  } catch (_) {
    oneToOneRestrictions.value = [];
  } finally {
    loading.value = false;
  }
};

const savePreferences = async (preference: GetStatusFormPreferences) => {
  await viewModel.saveGetStatusPreference(preference);
};

const headers = computed(
  () =>
    [
      {
        title: i18n.t("type"),
        key: "type",
        value: "type",
        sortable: false,
        align: "center",
      },
      {
        title: i18n.t("periodOfRestriction"),
        key: "period_of_restriction",
        value: "period_of_restriction",
        align: "center",
        sortRaw: (a: OneToOneRestriction, b: OneToOneRestriction) => {
          const dateA = new Date(a.restrictionStart).getTime();
          const dateB = new Date(b.restrictionStart).getTime();

          return dateA - dateB;
        },
      },
    ] as const,
);

watch(getStatusPreferences, newVal => {
  if (newVal && newVal.terminal) {
    if (!terminal.value) terminal.value = newVal.terminal;
    if (!terminalId.value) terminalId.value = newVal.terminal.id;
  }
});

watch(
  () => forwarderStore.forwarder,
  async (newForwarder, oldForwarder) => {
    if (newForwarder?.id !== oldForwarder?.id) {
      await fetchRestrictions();
    }
  },
);

onMounted(async () => {
  terminals.value = await viewModel.getTerminalsForDropdown();
  const userPreferences = await viewModel.getUserGetStatusPreference();
  if (userPreferences) {
    getStatusPreferences.value = userPreferences;
    if (userPreferences.terminal) {
      terminalId.value = userPreferences.terminal.id;
      terminalName.value = userPreferences.terminal.name;
      terminal.value = userPreferences.terminal;
      await fetchRestrictions();
    }
  }
});
</script>

<style scoped lang="scss">
@import "../../../scss/fade-transitions";
.radio-btn {
  margin-top: 0rem;
  padding-top: 0rem;
  color: #ffffffe6;
}
.v-card__title {
  display: block;
}
.max-content {
  width: max-content;
}
.restriction-col {
  max-height: 300rem;
}
</style>
